import React, { useState } from "react";
import { ChevronDown } from "lucide-react";
import { Select as AriaSelect, SelectProps as AriaSelectProps, ListBox, ListBoxItemProps, SelectValue, ValidationResult, Key } from "react-aria-components";
import { Description, FieldError, Label } from "@/edges/ui/Field";
import { Button } from "@/edges/ui/Button";
import { DropdownItem, DropdownSection, DropdownSectionProps } from "@/edges/ui/ListBox";
import { Popover } from "@/edges/ui/Popover";
import { composeTailwindRenderProps, focusRing } from "@/edges/utils";

// Replace tv() with a regular function
type SelectVariants = {
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg";
};
function getSelectClassName({
  isDisabled,
  size = "md"
}: SelectVariants = {}) {
  const baseClasses = [focusRing, "flex items-center text-start gap-4 w-full cursor-pointer border dark:border-white/10 shadow-[inset_0_1px_0_0_rgba(255,255,255,0.1)] dark:shadow-none rounded-sm pl-3 pr-2 py-2 min-w-[150px] transition dark:bg-zinc-700 hover:!bg-background-hover hover:!border-gray-200"];
  const sizeClasses = {
    sm: "h-[theme(controlElementSize.sm)]",
    md: "h-[theme(controlElementSize.md)]",
    lg: "h-[theme(controlElementSize.lg)]"
  }[size];
  const stateClasses = isDisabled ? "text-gray-200 dark:text-zinc-600 forced-colors:text-[GrayText] dark:bg-zinc-800 dark:border-white/5 forced-colors:border-[GrayText]" : " hover:bg-gray-100 pressed:bg-gray-200  group-invalid:border-red-600 forced-colors:group-invalid:border-[Mark]";
  return [...baseClasses, sizeClasses, stateClasses].filter(Boolean).join(" ");
}
export type SelectVariantType = SelectVariants;
export interface SelectProps<T extends object> extends Omit<AriaSelectProps<T>, "children" | "onSelectionChange"> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  items?: Iterable<T>;
  size: "sm" | "md" | "lg";
  children: React.ReactNode | ((item: T) => React.ReactNode);
  selectedKey?: Key;
  defaultSelectedKey?: Key;
  onSelectionChange?: (key: Key) => void;
  showErrors?: boolean;
}
export function Select<T extends object>({
  label,
  description,
  errorMessage,
  children,
  items,
  size,
  selectedKey: controlledSelectedKey,
  defaultSelectedKey,
  onSelectionChange,
  placeholder,
  showErrors = false,
  ...props
}: SelectProps<T>) {
  const [internalSelectedKey, setInternalSelectedKey] = useState<Key | null>(null);
  const selectedKey = controlledSelectedKey ?? internalSelectedKey ?? defaultSelectedKey;
  const handleSelectionChange = (key: Key) => {
    if (onSelectionChange) {
      onSelectionChange(key);
    } else {
      setInternalSelectedKey(key); // Update internal state if uncontrolled
    }
  };
  const SIZE_STYLES = {
    sm: "text-controlElementSm",
    md: "text-controlElementMd",
    lg: "text-controlElementLg"
  } as const;
  const sizeClasses = SIZE_STYLES[size];
  return <AriaSelect {...props} selectedKey={selectedKey} defaultSelectedKey={defaultSelectedKey} onSelectionChange={handleSelectionChange} placeholder={placeholder} className={composeTailwindRenderProps(props.className, "group flex flex-col gap-1")} data-sentry-element="AriaSelect" data-sentry-component="Select" data-sentry-source-file="Select.tsx">
      {label && <Label>{label}</Label>}
      <Button className={getSelectClassName({
      size
    })} variant="secondary" size={size} data-sentry-element="Button" data-sentry-source-file="Select.tsx">
        <SelectValue className={`flex-1 text-${sizeClasses} placeholder-shown:text-placeholder`} aria-label={selectedKey ? `Selected: ${String(selectedKey)}` : "Select an option"} data-sentry-element="SelectValue" data-sentry-source-file="Select.tsx" />
        <ChevronDown aria-hidden className="h-4 w-4 text-gray-600 group-disabled:text-gray-200 dark:text-zinc-400 dark:group-disabled:text-zinc-600 forced-colors:text-[ButtonText] forced-colors:group-disabled:text-[GrayText]" data-sentry-element="ChevronDown" data-sentry-source-file="Select.tsx" />
      </Button>
      {description && <Description>{description}</Description>}
      {showErrors && <FieldError>{errorMessage}</FieldError>}
      <Popover className="min-w-[--trigger-width]" data-sentry-element="Popover" data-sentry-source-file="Select.tsx">
        <ListBox items={items} className="max-h-[inherit] overflow-auto outline-none" data-sentry-element="ListBox" data-sentry-source-file="Select.tsx">
          {typeof children === "function" ? Array.from(items || []).map(item => children(item)) : children}
        </ListBox>
      </Popover>
    </AriaSelect>;
}
export function SelectItem(props: ListBoxItemProps) {
  return <DropdownItem {...props} data-sentry-element="DropdownItem" data-sentry-component="SelectItem" data-sentry-source-file="Select.tsx" />;
}
export function SelectSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} data-sentry-element="DropdownSection" data-sentry-component="SelectSection" data-sentry-source-file="Select.tsx" />;
}