/* eslint-disable no-case-declarations */
import React, { useState } from "react";
import { toSentenceCase } from "@/utils";
import { Event, EventType } from "@/@codegen/supergraph";
import { format, formatDistanceToNow } from "date-fns";
import { Tooltip } from "@/edges/ui/Tooltip";
import { DeviceDisconnectedEvent_0_0_1, DeviceDiscoveredEvent_0_0_1, DeviceUpdatedEvent_0_0_1 } from "@texturehq/events";
import { CodeEditor } from "../edges/ui/CodeEditor";
interface DisplayEvent {
  title: string;
  description: string;
  timestamp: string;
  severity?: "success" | "error" | "info" | "warning";
}
const getEventDisplay = (event: Event): DisplayEvent => {
  const timestamp = event.timestamp;
  let device;
  switch (event.eventType) {
    case EventType.CommandFailed:
      return {
        title: "Command Failed",
        description: "Command execution failed",
        timestamp,
        severity: "error"
      };
    case EventType.CommandSucceeded:
      return {
        title: "Command Executed",
        description: "Successfully executed command",
        timestamp,
        severity: "success"
      };
    case EventType.DeviceDisconnected:
      const deviceDisconnected = event.payload as DeviceDisconnectedEvent_0_0_1;
      device = deviceDisconnected?.data?.deviceModel || "Device";
      return {
        title: "Device Disconnected",
        description: `${device || "Device"} disconnected from the network`,
        timestamp,
        severity: "warning"
      };
    case EventType.DeviceDiscovered:
      const deviceDiscovered = event.payload as DeviceDiscoveredEvent_0_0_1;
      device = deviceDiscovered?.data?.deviceModel || "device";
      return {
        title: "New Device Found",
        description: `New ${device} discovered and added to the network`,
        timestamp,
        severity: "success"
      };
    case EventType.DeviceUpdated:
      const deviceUpdated = event.payload as DeviceUpdatedEvent_0_0_1;
      device = deviceUpdated?.data?.deviceModel || "Device";
      return {
        title: "Device Updated",
        description: `${device} information or settings were updated`,
        timestamp,
        severity: "info"
      };
    case EventType.EnrollmentApproved:
      return {
        title: "Enrollment Approved",
        description: "Device enrollment request was approved",
        timestamp,
        severity: "success"
      };
    case EventType.EnrollmentRejected:
      return {
        title: "Enrollment Rejected",
        description: "Device enrollment request was rejected",
        timestamp,
        severity: "error"
      };
    case EventType.EnrollmentSubmitted:
      return {
        title: "Enrollment Submitted",
        description: "New device enrollment request submitted",
        timestamp,
        severity: "info"
      };
    default:
      return {
        title: toSentenceCase(event.eventType),
        description: "Event occurred",
        timestamp,
        severity: "info"
      };
  }
};
export interface ActivityFeedEventProps {
  event: Event;
  index: number;
}
export const ActivityFeedEvent = ({
  event,
  index
}: ActivityFeedEventProps) => {
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const toggleExpand = (id: string) => {
    setExpandedEvents(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };
  const displayEvent = getEventDisplay(event);
  const timestamp = new Date(displayEvent.timestamp);
  const fullTimestamp = format(timestamp, "PPpp");
  return <div key={event.id || index} className="flex items-start space-x-4 border-b border-gray-100 pb-4 last:border-b-0" data-sentry-component="ActivityFeedEvent" data-sentry-source-file="ActivityFeedEvent.tsx">
      <div className="flex-shrink-0">
        <div className={`flex h-10 w-10 items-center justify-center rounded-full ${displayEvent.severity === "error" ? "bg-red-100 text-red-600" : displayEvent.severity === "success" ? "bg-green-100 text-green-600" : displayEvent.severity === "warning" ? "bg-yellow-100 text-yellow-600" : "bg-blue-100 text-blue-600"}`}>
          {displayEvent.severity === "error" && <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>}
          {displayEvent.severity === "success" && <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
            </svg>}
          {displayEvent.severity === "warning" && <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>}
          {displayEvent.severity === "info" && <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>}
        </div>
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm font-medium text-gray-900">
          {displayEvent.title}
        </p>
        <p className="mt-1 text-sm text-text-caption">
          {displayEvent.description}
        </p>
        <Tooltip content={fullTimestamp} data-sentry-element="Tooltip" data-sentry-source-file="ActivityFeedEvent.tsx">
          <p className="mt-1 cursor-help text-xs text-text-caption">
            {formatDistanceToNow(timestamp, {
            addSuffix: true
          })}
          </p>
        </Tooltip>
        <button onClick={() => toggleExpand(event.id)} className="text-sm text-blue-600 hover:text-blue-800">
          {expandedEvents[event.id] ? "Hide" : "Show"} raw payload
        </button>

        {expandedEvents[event.id] && <div className="mt-2">
            <CodeEditor value={JSON.stringify(event.payload, null, 2)} readOnly />
          </div>}
      </div>
    </div>;
};