import React from "react";
import { Icon } from "@/edges/ui/Icon";
import Link from "next/link";

// Replace tv variants with type definitions
type MenuLinkSize = "sm" | "lg";
type MenuLinkAlign = "left" | "right";
type MenuLinkState = "active" | "default";
export interface linkVariants {
  size?: MenuLinkSize;
  align?: MenuLinkAlign;
  state?: MenuLinkState;
}
export interface MenuLinkProps extends linkVariants {
  className?: string;
  children: React.ReactNode;
  iconName: string;
  href: string;
  isActive?: boolean;
  showLabel?: boolean;
}

// Helper function to generate className string
const getMenuLinkClasses = ({
  size = "lg",
  align = "left",
  state = "default",
  isActive = false,
  className = ""
}: {
  size: MenuLinkSize;
  align: MenuLinkAlign;
  state: MenuLinkState;
  isActive: boolean;
  className?: string;
}) => {
  const baseClasses = "flex w-full items-center gap-6 no-underline cursor-pointer";
  const sizeClasses = {
    sm: "h-8",
    lg: "pl-6 h-14 hover:text-action-primary dark:hover:text-white duration-200"
  }[size];
  const alignClasses = {
    left: "justify-start",
    right: "justify-end"
  }[align];
  const stateClasses = isActive ? "bg-background-menu-active text-action-primary font-semibold dark:text-action-primaryDark hover:cursor-pointer hover:text-inherit border-l-2 border-action-primary dark:border-action-primary" : "hover:cursor-pointer dark:hover:text-action-primaryDark";
  return `${baseClasses} ${sizeClasses} ${alignClasses} ${stateClasses} ${className}`.trim();
};
const MenuLink = ({
  size = "lg",
  align = "left",
  state = "default",
  children,
  iconName,
  isActive = false,
  showLabel = true,
  className,
  href = "/"
}: MenuLinkProps) => {
  return <Link href={href} data-sentry-element="Link" data-sentry-component="MenuLink" data-sentry-source-file="MenuLink.tsx">
      <div className={getMenuLinkClasses({
      size,
      align,
      state,
      isActive,
      className
    })}>
        <Icon name={iconName} data-sentry-element="Icon" data-sentry-source-file="MenuLink.tsx" />
        <div className={`flex pt-[1px] ${showLabel ? "" : "md:hidden"}`}>
          {children}
        </div>
      </div>
    </Link>;
};
export { MenuLink };