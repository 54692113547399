import React from "react";
import { composeRenderProps, Button as RACButton, ButtonProps as RACButtonProps } from "react-aria-components";
import { Icon } from "@/edges/ui/Icon";
import { focusRing } from "@/edges/utils";
export interface ButtonProps extends RACButtonProps {
  variant?: "primary" | "secondary" | "destructive" | "icon" | "unstyled" | "ghost";
  size?: "sm" | "md" | "lg" | "xl";
  badgeNumber?: number;
  fullWidth?: boolean;
  icon?: string;
  isLoading?: boolean;
}
const baseStyles = "px-5 flex font-medium justify-center items-center gap-3 text-center transition rounded-default";
const variantStyles = {
  primary: "bg-action-primary border-none text-text-onPrimary hover:bg-action-primary-hover pressed:bg-blue-800",
  secondary: "border hover:border-text hover:bg-background-hover  pressed:border-text focus:outline-none focus:border-text",
  ghost: "border-none hover:bg-gray-100 dark:hover:bg-transparent pressed:border-text dark:pressed:border-textDark dark:text-white dark:hover:border-textDark focus:outline-none focus:border-text",
  destructive: "border hover:border-text text-destructive dark:text-destructiveDark hover:bg-gray-100 dark:hover:bg-transparent pressed:border-text dark:pressed:border-textDark dark:hover:border-textDark",
  icon: "p-0 h-auto text-inherit",
  unstyled: ""
};
const sizeStyles = {
  sm: "h-[theme(controlElementSize.sm)] text-controlElementSm",
  md: "h-[theme(controlElementSize.md)] text-controlElementMd",
  lg: "h-[theme(controlElementSize.lg)] text-controlElementLg",
  xl: "h-[theme(controlElementSize.xl)] text-controlElementXl"
};
const widthStyles = {
  full: "w-full",
  default: "w-fit"
};
const disabledStyles = "opacity-60 cursor-not-allowed";
const enabledStyles = "opacity-100 cursor-pointer";
function getButtonStyles(props: ButtonProps) {
  const {
    variant = "primary",
    size = "md",
    isDisabled,
    fullWidth = false,
    isLoading = false
  } = props;
  let styles;
  if (variant === "unstyled") {
    styles = "";
  } else {
    styles = `${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]}`;
  }
  if (isDisabled || isLoading) {
    styles += ` ${disabledStyles}`;
  } else {
    styles += ` ${enabledStyles}`;
  }
  if (fullWidth) {
    styles += ` ${widthStyles.full}`;
  } else {
    styles += ` ${widthStyles.default}`;
  }
  return `${styles} ${focusRing()}`;
}
export function Button(props: ButtonProps) {
  const {
    icon,
    children,
    isLoading = false,
    size
  } = props;
  let iconSize = 20;
  if (size === "sm") {
    iconSize = 14;
  } else if (size === "lg") {
    iconSize = 28;
  }
  const renderChildren = () => {
    if (isLoading) {
      return <Icon name="CircleNotch" className="animate-spin" size={iconSize} />;
    }
    return <React.Fragment>
        {icon ? <Icon name={icon} size={iconSize} /> : null}
        {typeof children === "function" ? children({
        isHovered: false,
        isPressed: false,
        isFocused: false,
        isFocusVisible: false,
        isDisabled: !!props.isDisabled,
        isPending: false,
        defaultChildren: null
      }) : children}
      </React.Fragment>;
  };
  const buttonComponent = <RACButton {...props} className={composeRenderProps(props.className, className => `${getButtonStyles(props)} ${className || ""}`)}>
      {renderChildren()}
    </RACButton>;
  if (props.badgeNumber && props.badgeNumber > 0) {
    return <div className="relative">
        {buttonComponent}
        <span className="bg-text absolute left-0 top-0 -ml-2 -mt-1 flex h-4 w-4 items-center justify-center rounded-full text-xs text-white">
          {props.badgeNumber}
        </span>
      </div>;
  } else {
    return buttonComponent;
  }
}