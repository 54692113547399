"use client";

import { createContext, useContext, useEffect, useState, useCallback } from "react";

// Moved to direct const variable in order to remove dependence on config tied to dash-next directly in edges
// Eventually we will want to move this folder out of dash-next and into a shared location
const preventDarkMode = false;
interface ColorModeContextType {
  colorMode: string;
  isDarkTheme: boolean;
  toggleTheme: () => void;
}
const ColorModeContext = createContext<ColorModeContextType>({
  colorMode: "light",
  isDarkTheme: false,
  toggleTheme: () => {}
});
export const useColorMode = () => useContext(ColorModeContext);
interface ColorModeProviderProps {
  children: React.ReactNode;
}
export const ColorModeProvider: React.FC<ColorModeProviderProps> = ({
  children
}) => {
  const [colorMode, setColorMode] = useState("light");
  const isDarkTheme = colorMode === "dark";
  useEffect(() => {
    // Get initial theme from localStorage or body class
    const savedTheme = localStorage.getItem("theme");
    const isDarkThemeActive = typeof document !== "undefined" && document.body.classList.contains("dark-theme");
    const initialTheme = savedTheme || (isDarkThemeActive ? "dark" : "light");
    setColorMode(initialTheme);

    // Add dark-theme class if needed
    if (initialTheme === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }
  }, []);
  const toggleTheme = useCallback(() => {
    setColorMode(prevMode => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("theme", newMode);
      if (newMode === "dark") {
        document.body.classList.add("dark-theme");
      } else {
        document.body.classList.remove("dark-theme");
      }
      return newMode;
    });
  }, []);
  if (preventDarkMode) {
    return <ColorModeContext.Provider value={{
      colorMode: "light",
      isDarkTheme: false,
      toggleTheme: () => {}
    }}>
        {children}
      </ColorModeContext.Provider>;
  }
  return <ColorModeContext.Provider value={{
    colorMode,
    isDarkTheme,
    toggleTheme
  }} data-sentry-element="unknown" data-sentry-component="ColorModeProvider" data-sentry-source-file="ColorModeProvider.tsx">
      {children}
    </ColorModeContext.Provider>;
};