import React from "react";
import { AxisBottom as VisxAxisBottom, AxisScale } from "@visx/axis";
import { scaleTime } from "@visx/scale";
import { TextProps } from "@visx/text";
import { defaultMargin } from "@/edges/types/ChartProps";
import { DateRanges } from "@/utils/date";
import { useTailwindTheme } from "@/hooks";

/**
 * Props for the CommonAxisBottom component
 * @property {number} top - Vertical offset from the top
 * @property {ReturnType<typeof scaleTime>} scale - Scale function for the axis
 */
interface CommonAxisBottomProps {
  top: number;
  scale: AxisScale;
  width: number;
  selectedRange: DateRanges;
}
const WIDTH_BREAKPOINTS = {
  NARROW: 640,
  MEDIUM: 1024
};
export const getOptimalTickCount = (width: number, domain: [number, number]) => {
  const [startDate, endDate] = domain.map(d => new Date(d));
  const diffInDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
  if (width < WIDTH_BREAKPOINTS.NARROW) {
    return 3;
  }
  if (width < WIDTH_BREAKPOINTS.MEDIUM) {
    return 5;
  }

  // For large width
  if (diffInDays >= 3 && diffInDays <= 7) {
    return diffInDays;
  }
  return 7;
};
const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const DATE_RANGES = {
  DAY: 1,
  WEEK: 7,
  MONTH: 30
};
const FORMAT_OPTIONS = {
  TIME: {
    hour: "numeric",
    minute: "2-digit"
  },
  WEEK: {
    weekday: "short"
  },
  MONTH: {
    month: "short",
    day: "numeric"
  }
} as const;
const calculateDiffInDays = (domain: [number, number]): number => {
  return Math.ceil((domain[1] - domain[0]) / ONE_DAY_MS);
};
export const formatXAxisTick = (value: Date, domain: [number, number]) => {
  const date = new Date(value);
  const diffInDays = calculateDiffInDays(domain);
  if (diffInDays <= DATE_RANGES.DAY) {
    return date.toLocaleTimeString([], FORMAT_OPTIONS.TIME);
  }
  if (diffInDays <= DATE_RANGES.WEEK) {
    return date.toLocaleDateString([], FORMAT_OPTIONS.WEEK);
  }
  if (diffInDays <= DATE_RANGES.MONTH) {
    return date.toLocaleDateString([], FORMAT_OPTIONS.MONTH);
  }
  return date.toLocaleDateString();
};
const WIDE_CHART_THRESHOLD = 520;
const TICK_COUNT = {
  WIDE: 5,
  NARROW: 3
};
interface AxisBottomProps {
  top: number;
  scale: AxisScale;
  stroke?: string;
  tickStroke?: string;
  domain: [number, number];
  width: number;
}
const AxisBottom: React.FC<AxisBottomProps> = ({
  top,
  scale,
  width,
  domain
}) => {
  const {
    getThemeValue
  } = useTailwindTheme();
  const borderColor = String(getThemeValue("colors.border.default") || "#E5E7EB");
  const textColor = String(getThemeValue("colors.text.caption") || "#374151");
  return <VisxAxisBottom data-testid="axis-bottom" top={top} scale={scale} numTicks={getOptimalTickCount(width - defaultMargin.left - defaultMargin.right, domain)} stroke={borderColor} tickStroke={borderColor} tickFormat={value => formatXAxisTick(value as Date, domain)} tickLabelProps={() => ({
    className: "font-body text-text-caption text-xs",
    fill: textColor,
    textAnchor: "middle",
    dx: "0em",
    dy: "0.75em"
  }) as Partial<TextProps>} data-sentry-element="VisxAxisBottom" data-sentry-component="AxisBottom" data-sentry-source-file="AxisBottom.tsx" />;
};
export { AxisBottom };