const theme = {
  screens: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    mobile: { max: "640px" }, // Custom 'mobile' breakpoint for screens <= 640px
  },
  colors: {
    primary: "#444ae1",
    primaryDark: "#8e92f0",
    text: "#333333",
    textDark: "#e5e7eb",
    textMuted: "#4b5563",
    textFaint: "#6b7280",
    textMutedDark: "#d1d5db",
    textFaintDark: "#9ca3af",
    appBg: "#f9fafb",
    appBgDark: "#1d1f3b",
    componentBg: "#ffffff",
    componentBgDark: "#262c48",
    borders: "#e5e7eb",
    bordersDark: "#343c62",
    highlight: "#d7dafb",
    highlightDark: "#343c62",
    placeholder: "#b3b3b3",
    destructive: "#b91c1c",
    destructiveDark: "#fca5a5",
    loading: "bg-gradient-to-r from-[#eef0fc] to-[#f6f7ff]",
    success: "#34d399",
    warning: "#fef08a",
    charts: ["#0082ff", "#00a5ef", "#00bfc3", "#34d399"],
    data: {
      categorical: [
        "#a3eae4",
        "#91a0ff",
        "#ffd1a3",
        "#ffabc8",
        "#c1b8ff",
        "#e0ffb8",
      ],
      sequential: {
        viridis: [
          "#fafa37",
          "#d6e654",
          "#b2d373",
          "#88be8c",
          "#5fa2a4",
          "#3d7eaa",
          "#2a60b1",
          "#1b3a99",
          "#132353",
          "#110f39",
        ],
        magma: [
          "#fcf5cd",
          "#f5db95",
          "#e9af7c",
          "#db7d70",
          "#b9577a",
          "#883482",
          "#6a1f78",
          "#4b1277",
          "#2d0b42",
          "#0b0511",
        ],
        rose: [
          "#fef3e7",
          "#f5c7e0",
          "#ec9bce",
          "#d86db0",
          "#be4a8b",
          "#9d2f76",
          "#78176c",
          "#56135c",
          "#371344",
          "#190b29",
        ],
        cerulean: [
          "#e2f7f2",
          "#b6e4e8",
          "#8bd3d7",
          "#60b2c7",
          "#3d91b6",
          "#22698e",
          "#0e486a",
          "#0a314d",
          "#081e33",
          "#030e1d",
        ],
        forest: [
          "#f4fad8",
          "#d6e6a5",
          "#b2cd73",
          "#88af52",
          "#5f8835",
          "#3d6426",
          "#2a461a",
          "#1b2e13",
          "#13200d",
          "#0b1305",
        ],
      },
      diverging: {
        orangeYellowSeafoam: [
          "#6b2c1a",
          "#a24b1e",
          "#cb7131",
          "#e2a05d",
          "#f3e1a3",
          "#c1e4d2",
          "#7eb8b1",
          "#4c8c89",
          "#2b6262",
          "#1b3d3c",
        ],
        redYellowBlue: [
          "#4e142d",
          "#8b2d4e",
          "#c15a4a",
          "#db8b6a",
          "#f3e1a3",
          "#bfd4c7",
          "#7c9eb9",
          "#4770a3",
          "#2e4d8c",
          "#18274e",
        ],
        redBlue: [
          "#4e142d",
          "#8b2d4e",
          "#c15a7a",
          "#db8b9e",
          "#f3e1f4",
          "#c7bfe2",
          "#9ea7ce",
          "#7080b5",
          "#4d5e99",
          "#2b3d70",
        ],
      },
    },
    iris: {
      "50": "#f6f7ff",
      "100": "#d7dafb",
      "200": "#b3b7f6",
      "300": "#8e92f0",
      "400": "#696be8",
      "500": "#444ae1",
      "600": "#3b41cd",
      "700": "#3338b8",
      "800": "#2b30a2",
      "900": "#24288c",
      "950": "#1c1f72",
    },
    black: "#000000",
    white: "#ffffff",
    gray: {
      "50": "#f9fafb",
      "100": "#f3f4f6",
      "200": "#e5e7eb",
      "300": "#d1d5db",
      "400": "#9ca3af",
      "500": "#6b7280",
      "600": "#4b5563",
      "700": "#374151",
      "800": "#1f2937",
      "900": "#111827",
      "950": "#030712",
    },
    green: {
      "50": "#ecfdf5",
      "100": "#d1fae5",
      "200": "#a7f3d0",
      "300": "#6ee7b7",
      "400": "#34d399",
      "500": "#10b981",
      "600": "#059669",
      "700": "#047857",
      "800": "#065f46",
      "900": "#064e3b",
      "950": "#022c22",
    },
    red: {
      "50": "#fef2f2",
      "100": "#fee2e2",
      "200": "#fecaca",
      "300": "#fca5a5",
      "400": "#f87171",
      "500": "#ef4444",
      "600": "#dc2626",
      "700": "#b91c1c",
      "800": "#991b1b",
      "900": "#7f1d1d",
      "950": "#450a0a",
    },
    yellow: {
      "50": "#fefce8",
      "100": "#fef9c3",
      "200": "#fef08a",
      "300": "#fde047",
      "400": "#facc15",
      "500": "#eab308",
      "600": "#ca8a04",
      "700": "#a16207",
      "800": "#854d0e",
      "900": "#713f12",
      "950": "#422006",
    },
    blue: {
      "200": "#93c5fd",
      "900": "#1e3a8a",
    },
    deviceState: {
      unknown: "#e5e7eb", // bg-gray-50
      off: "#1f2937", // bg-gray-800
      idle: "#f9a8d4", // rose-300
      heat: "#fca5a5", // bg-red-300
      eco: "#6ee7b7", // bg-green-300
      cool: "#93c5fd", // bg-blue-300
      auto: "#fdba74", // bg-orange-300
      charging: "#93c5fd", // bg-blue-300
      discharging: "#fcd34d", // bg-yellow-300
      on: "#6ee7b7", // bg-green-300
      exporting: "#6ee7b7", // bg-green-300
      importing: "#fca5a5", // bg-red-300
    },
    transparent: "transparent",
  },
  spacing: {
    px: "1px",
    "0": "0",
    "1": "0.25rem",
    "2": "0.5rem",
    "3": "0.75rem",
    "4": "1rem",
    "5": "1.25rem",
    "6": "1.5rem",
    "8": "2rem",
    "10": "2.5rem",
    "12": "3rem",
    "13": "3.25rem",
    "14": "3.5rem",
    "15": "3.75rem",
    "16": "4rem",
    "20": "5rem",
    "24": "6rem",
    "32": "8rem",
    "40": "10rem",
    "48": "12rem",
    "56": "14rem",
    "64": "16rem",
  },
  backgroundImage: {
    "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
    "gradient-conic":
      "conic-gradient(from 180deg at 50% 50%, var(--tw-gradient-stops))",
    "gradient-pulse": "linear-gradient(to right, #d1d5db, #e5e7eb)",
  },
  backgroundPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  backgroundSize: {
    auto: "auto",
    cover: "cover",
    contain: "contain",
  },
  borderRadius: {
    none: "0",
    sm: "0.0625rem",
    default: "0.25rem",
    lg: "0.5rem",
    full: "9999px",
  },
  borderWidth: {
    DEFAULT: "1px",
    "0": "0",
    "1": "1px",
    "2": "2px",
    "4": "4px",
    "8": "8px",
  },
  boxShadow: {
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    "3xl": "0 35px 60px -15px rgba(0, 0, 0, 0.3)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    outline: "0 0 0 3px rgba(66, 153, 225, 0.5)",
    none: "none",
  },
  container: {
    padding: "1rem",
  },
  controlElementSize: {
    sm: "1.625rem", // 26px
    md: "2rem", // 32px
    lg: "2.5rem", // 40px
    xl: "3.25rem", // 52px
  },
  cursor: {
    auto: "auto",
    default: "default",
    pointer: "pointer",
    wait: "wait",
    text: "text",
    move: "move",
    "not-allowed": "not-allowed",
  },
  fill: {
    current: "currentColor",
  },
  flex: {
    "1": "1 1 0%",
    auto: "1 1 auto",
    initial: "0 1 auto",
    none: "none",
  },
  flexGrow: {
    "0": "0",
    default: "1",
  },
  flexShrink: {
    "0": "0",
    default: "1",
  },
  fontFamily: {
    body: ["var(--font-inter), sans-serif"],
    sans: ["var(--font-inter), sans-serif"],
    serif: ["Merriweather, serif"],
    display: ["var(--font-inter), sans-serif"],
    epilogue: ["var(--font-epilogue), sans-serif"],
    inter: ["var(--font-inter), sans-serif"],
    mono: [
      "Menlo",
      "Monaco",
      "Consolas",
      "Liberation Mono",
      "Courier",
      "monospace",
    ],
  },
  fontSize: (theme: any) => ({
    ...theme("spacing"),
    headingXs: ["16px", { lineHeight: "140%" }],
    headingSm: ["20px", { lineHeight: "140%" }],
    headingMd: ["24px", { lineHeight: "140%" }],
    headingLg: ["30px", { lineHeight: "140%" }],
    headingXl: ["40px", { lineHeight: "140%" }],
    controlElementSm: ["14px", { lineHeight: "24px" }],
    controlElementMd: ["14px", { lineHeight: "32px" }],
    controlElementLg: ["16px", { lineHeight: "40px" }],
    controlElementXl: ["18px", { lineHeight: "52px" }],
  }),
  fontWeight: {
    hairline: "100",
    thin: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },
  inset: {
    "0": "0",
    auto: "auto",
  },
  letterSpacing: {
    tighter: "-0.05em",
    tight: "-0.025em",
    normal: "0",
    wide: "0.025em",
    wider: "0.05em",
    widest: "0.1em",
  },
  lineHeight: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
  },
  listStyleType: {
    none: "none",
    disc: "disc",
    decimal: "decimal",
  },
  maxHeight: {
    full: "100%",
    screen: "100vh",
  },
  maxWidth: {
    xs: "20rem",
    sm: "24rem",
    md: "28rem",
    lg: "32rem",
    xl: "36rem",
    "2xl": "42rem",
    "3xl": "48rem",
    "4xl": "56rem",
    "5xl": "64rem",
    "6xl": "72rem",
    content: "75rem",
    full: "100%",
  },
  minHeight: {
    "0": "0",
    full: "100%",
    screen: "100vh",
  },
  minWidth: {
    "0": "0",
    full: "100%",
  },
  objectPosition: {
    bottom: "bottom",
    center: "center",
    left: "left",
    "left-bottom": "left bottom",
    "left-top": "left top",
    right: "right",
    "right-bottom": "right bottom",
    "right-top": "right top",
    top: "top",
  },
  opacity: {
    "0": "0",
    "25": "0.25",
    "50": "0.5",
    "75": "0.75",
    "100": "1",
  },
  order: {
    first: "-9999",
    last: "9999",
    none: "0",
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9",
    "10": "10",
    "11": "11",
    "12": "12",
  },
  stroke: {
    current: "currentColor",
  },
  zIndex: {
    auto: "auto",
    "0": "0",
    "10": "10",
    "20": "20",
    "30": "30",
    "40": "40",
    "50": "50",
  },
  animation: {
    "fade-in": "fade-in 250ms ease-in forwards",
  },
  keyframes: {
    "fade-in": {
      "0%": { opacity: "0" },
      "100%": { opacity: "1" },
    },
  },
};

export default theme;
