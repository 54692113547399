// @ts-nocheck

import Papa from "papaparse";
import { saveAs } from "file-saver";
import { scaleLinear, scaleTime } from "@visx/scale";
import { extent, max } from "d3-array";
import { defaultMargin } from "./ChartProps";
import { getYFormatSettings, YFormatType } from "./utils.js";
export function handleChartExport(type: "csv" | "svg" | "png", data: any[], xLabel: string, yLabel: string, categoryLabel: string, svgRef: React.RefObject<SVGSVGElement>) {
  if (type === "csv") {
    const csvData = Papa.unparse(data.map(d => ({
      [xLabel]: d.xValue.toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      }),
      [yLabel]: d.yValue,
      [categoryLabel]: d.categoryValue
    })));
    const blob = new Blob([csvData], {
      type: "text/csv;charset=utf-8;"
    });
    saveAs(blob, "chart_data.csv");
  } else if (type === "svg" && svgRef.current) {
    try {
      const serializer = new XMLSerializer();
      const svgBlob = new Blob([serializer.serializeToString(svgRef.current)], {
        type: "image/svg+xml"
      });
      saveAs(svgBlob, "chart.svg");
    } catch (error) {
      console.error("Failed to export SVG:", error);
      throw new Error("Failed to export SVG image");
    }
  } else if (type === "png" && svgRef.current) {
    let url: string | null = null;
    try {
      const svgElement = svgRef.current;
      const svgClone = svgElement.cloneNode(true) as SVGElement;
      const textElements = svgClone.getElementsByTagName("text");
      for (const text of textElements) {
        text.style.fontFamily = "Arial";
      }
      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svgClone);
      const svgBlob = new Blob([svgString], {
        type: "image/svg+xml;charset=utf-8"
      });
      url = URL.createObjectURL(svgBlob);
      const image = new Image();
      const imageLoadTimeout = setTimeout(() => {
        if (url) URL.revokeObjectURL(url);
        throw new Error("Image load timeout");
      }, 30000);
      image.onload = () => {
        clearTimeout(imageLoadTimeout);
        const padding = 40;
        const canvas = document.createElement("canvas");
        canvas.width = (svgElement.clientWidth + padding * 2) * 2;
        canvas.height = (svgElement.clientHeight + padding * 2) * 2;
        const context = canvas.getContext("2d");
        if (context) {
          context.scale(2, 2);
          context.fillStyle = "white";
          context.fillRect(0, 0, canvas.width / 2, canvas.height / 2);
          context.drawImage(image, padding, padding);
          canvas.toBlob(blob => {
            if (blob) {
              saveAs(blob, "chart.png");
            }
            if (url) URL.revokeObjectURL(url);
          }, "image/png");
        }
      };
      image.onerror = () => {
        clearTimeout(imageLoadTimeout);
        if (url) URL.revokeObjectURL(url);
        throw new Error("Failed to load image");
      };
      image.src = url;
    } catch (error) {
      if (url) URL.revokeObjectURL(url);
      console.error("Failed to export PNG:", error);
      throw new Error("Failed to export PNG image");
    }
  }
}