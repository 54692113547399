import React from "react";
const sizeVariants = {
  xs: "text-headingXs font-semibold",
  sm: "text-headingSm font-semibold",
  md: "text-headingMd font-semibold",
  lg: "text-headingLg font-semibold",
  xl: "text-headingXl font-semibold"
} as const;
const heightVariants = {
  page: "h-16 leading-[62px]"
} as const;
export type HeadingSize = keyof typeof sizeVariants;
export type HeadingHeight = keyof typeof heightVariants;
export interface HeadingProps {
  tag?: keyof JSX.IntrinsicElements;
  size?: HeadingSize;
  height?: HeadingHeight;
  className?: string;
  children?: React.ReactNode;
}
function Heading({
  tag: Tag = "h1",
  size = "lg",
  height,
  className = "",
  children
}: HeadingProps) {
  const sizeClasses = sizeVariants[size];
  const heightClasses = height ? heightVariants[height] : "";
  return <Tag className={`${sizeClasses} ${heightClasses} ${className}`} data-sentry-element="Tag" data-sentry-component="Heading" data-sentry-source-file="Heading.tsx">
      {children}
    </Tag>;
}
export { Heading };