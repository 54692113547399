import React from "react";

import { useMetricsRepository } from "@/repositories/metricsRepository";

import { toast } from "react-hot-toast";

import { useTranslations } from "next-intl";

import { useSelectedWorkspace } from "@/state/user/useSelectedWorkspace";

import { DateRanges, parseDateRange } from "@/utils/date";

import { BaseDataPoint } from "@/components/edges/types/ChartProps";

// todo: add analytics
// import useAnalytics from "@/hooks/useAnalytics";
import {
  WindowSize,
  MetricsQueryGroupByFacet,
  MetricQueryFilter,
  DataPoint,
  MetricQueryInput,
} from "@/@codegen/supergraph";

export const mapData = (data: DataPoint) => {
  return {
    xValue: new Date(data.ts),
    yValue: data.v,
  } as BaseDataPoint;
};

/**
 * Energy related data is stored as Wh for capacity and W for rates.
 *
 * We typically, however, want to display this data in KWh or KW.
 *
 * We seem to keep flip flopping this presentation and showing the incorrect units/data.
 * Hoping a normalization at this layer helps resolve at various presentation layers.
 *
 * This should likely be moved into the AreaChart if we want to dynamically change the units.
 * The getYFormatSettings function does something similar to this, but only applies to the tooltips.
 */
export function normalizeEnergyData(
  dataPoints: BaseDataPoint[],
): BaseDataPoint[] {
  return dataPoints.map((data) => {
    return {
      ...data,
      yValue: data.yValue / 1000,
    };
  });
}

/**
 * We display or say emissions data is in kilograms CO2, but it is stored as pounds CO2.
 * The default unit from WattTime is CO2 lbs/MWh for the signal, then we derive to CO2 lbs.
 *
 * For now we will convert the pounds to kilograms here, to impact the presentation layer.
 */
export function normalizeEmissionsData(
  dataPoints: BaseDataPoint[],
): BaseDataPoint[] {
  return dataPoints.map((data) => {
    return {
      ...data,
      yValue: data.yValue * 0.453592,
    };
  });
}

const checkValidId = (id: string | undefined) => {
  return typeof id === "string" && id !== "";
};

interface UseMetricsProps {
  /**
   * if undefined, we expect no value
   * if empty array, we do not call the API because we expect at least one value and we have to wait ( probably a previous hook )
   */
  siteIds?: (string | undefined)[];
  /**
   * if undefined, we expect no value
   * if empty array, we do not call the API because we expect at least one value and we have to wait ( probably a previous hook )
   */
  deviceIds?: (string | undefined)[];
  groupBy?: MetricsQueryGroupByFacet;
}
function useMetrics({ deviceIds, siteIds, groupBy }: UseMetricsProps) {
  const { selectedWorkspace } = useSelectedWorkspace();

  const [selectedWindowSize, setSelectedWindowSize] =
    React.useState<WindowSize>(WindowSize.Hour);

  const [selectedRange, setSelectedRange] = React.useState<DateRanges>(
    DateRanges.Last7Days,
  );
  const parsedSelectedRange = React.useMemo(() => {
    return parseDateRange(selectedRange);
  }, [selectedRange]);

  const input = React.useMemo(() => {
    if (
      !selectedWorkspace?.id ||
      (Array.isArray(siteIds) && !siteIds.some(checkValidId)) ||
      (Array.isArray(deviceIds) && !deviceIds.some(checkValidId))
    ) {
      return;
    }
    const filter: MetricQueryFilter = {
      workspaceId: selectedWorkspace.id,
      range: parsedSelectedRange,
    };
    if (Array.isArray(siteIds) && siteIds.some(checkValidId)) {
      filter.siteId = siteIds.filter(checkValidId) as string[];
    }
    if (Array.isArray(deviceIds) && deviceIds.some(checkValidId)) {
      filter.deviceId = deviceIds.filter(checkValidId) as string[];
    }
    const input: MetricQueryInput = {
      filter,
      window: { size: selectedWindowSize },
    };
    if (groupBy) {
      input.groupBy = groupBy;
    }
    return input;
  }, [
    siteIds,
    deviceIds,
    selectedWorkspace?.id,
    selectedRange,
    selectedWindowSize,
    groupBy,
  ]);

  return {
    input,
    selectedRange,
    onRangeChange: setSelectedRange,
    selectedWindowSize,
    onWindowSizeChange: setSelectedWindowSize,
    allowDatePicker: false,
  };
}

interface UseFetchEnergyProps extends UseMetricsProps {
  fetcher: (input: MetricQueryInput) => Promise<any>;
  errorMessage: string;
}

function useFetchEnergy({
  siteIds,
  deviceIds,
  groupBy,
  fetcher,
  errorMessage,
}: UseFetchEnergyProps) {
  const { input, ...metricsProps } = useMetrics({
    siteIds,
    deviceIds,
    groupBy,
  });

  const [data, setData] = React.useState<BaseDataPoint[]>([]);

  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!input) {
      return;
    }
    setError(null);
    fetcher(input)
      .then((res: any) => {
        const data = res?.data?.map(mapData);
        setData(data ?? []);
      })
      .catch((error) => {
        console.error(error);
        setError(errorMessage);
        toast.error(errorMessage);
      });
  }, [JSON.stringify(input)]);

  return {
    data,
    error,
    ...metricsProps,
  };
}

export function useFetchEnergyConsumption({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchEnergyConsumption } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    groupBy: MetricsQueryGroupByFacet.Site,
    fetcher: fetchEnergyConsumption,
    errorMessage: t("errorFetchingConsumptionData"),
  });

  return {
    title: t("electricityConsumedKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyProduction({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchEnergyProduction } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    groupBy: MetricsQueryGroupByFacet.Site,
    fetcher: fetchEnergyProduction,
    errorMessage: t("errorFetchingProductionData"),
  });

  return {
    title: t("electricityProducedKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyStorage({ siteIds, deviceIds }: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchEnergyStorage } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    groupBy: MetricsQueryGroupByFacet.Site,
    fetcher: fetchEnergyStorage,
    errorMessage: t("errorFetchingStorageData"),
  });

  return {
    title: t("electricityStoredKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyTotalCapacity({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchTotalEnergyCapacity } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    fetcher: fetchTotalEnergyCapacity,
    errorMessage: t("errorFetchingCapacityData"),
  });

  return {
    title: t("totalCapacityKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyTotalConsumption({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchTotalEnergyConsumption } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    fetcher: fetchTotalEnergyConsumption,
    errorMessage: t("errorFetchingConsumptionData"),
  });

  return {
    title: t("totalConsumptionKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyTotalProduction({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchTotalEnergyProduction } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    fetcher: fetchTotalEnergyProduction,
    errorMessage: t("errorFetchingProductionData"),
  });

  return {
    title: t("totalProductionKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyTotalStorage({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchTotalEnergyStorage } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    fetcher: fetchTotalEnergyStorage,
    errorMessage: t("errorFetchingStorageData"),
  });

  return {
    title: t("totalStorageKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchTotalGridEnergy({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchTotalGridEnergy } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    fetcher: fetchTotalGridEnergy,
    errorMessage: t("errorFetchingTotalGridEnergyData"),
  });

  return {
    title: t("totalGridEnergyKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchEnergyEmissions({
  siteIds,
  deviceIds,
}: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchEnergyEmissions } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    deviceIds,
    groupBy: MetricsQueryGroupByFacet.Site,
    fetcher: fetchEnergyEmissions,
    errorMessage: t("errorFetchingEmissionsData"),
  });

  return {
    title: t("emissionsKgCo2e"),
    data: normalizeEmissionsData(data),
    error,
    props: metricsProps,
  };
}

export function useFetchGridEnergy({ siteIds }: UseMetricsProps) {
  const t = useTranslations("metrics");

  const { fetchGridEnergy } = useMetricsRepository();

  const { data, error, ...metricsProps } = useFetchEnergy({
    siteIds,
    groupBy: MetricsQueryGroupByFacet.Site,
    fetcher: fetchGridEnergy,
    errorMessage: t("errorFetchingGridEnergyData"),
  });

  return {
    title: t("gridEnergyKwh"),
    data: normalizeEnergyData(data),
    error,
    props: metricsProps,
  };
}
