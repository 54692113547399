// @ts-nocheck

import {
  processGraphQLOperationErrorsAndThrow,
  processSingleOperationGraphQLResponse,
} from "@/utils";

import {
  useEnergyConsumptionLazyQuery,
  useEnergyProductionLazyQuery,
  useEnergyStorageLazyQuery,
  useTotalEnergyCapacityLazyQuery,
  useTotalEnergyConsumptionLazyQuery,
  useTotalEnergyProductionLazyQuery,
  useTotalEnergyStorageLazyQuery,
  useTotalGridEnergyLazyQuery,
  useEnergyEmissionsLazyQuery,
  MetricQueryInput,
  useGridEnergyLazyQuery,
} from "@/@codegen/supergraph";

export function useMetricsRepository() {
  const [energyConsumptionLazyQuery] = useEnergyConsumptionLazyQuery();
  const [energyProductionLazyQuery] = useEnergyProductionLazyQuery();
  const [energyStorageLazyQuery] = useEnergyStorageLazyQuery();
  const [gridEnergyLazyQuery] = useGridEnergyLazyQuery();
  const [totalEnergyCapacityLazyQuery] = useTotalEnergyCapacityLazyQuery();
  const [totalEnergyConsumptionLazyQuery] =
    useTotalEnergyConsumptionLazyQuery();
  const [totalEnergyProductionLazyQuery] = useTotalEnergyProductionLazyQuery();
  const [totalEnergyStorageLazyQuery] = useTotalEnergyStorageLazyQuery();
  const [totalGridEnergyLazyQuery] = useTotalGridEnergyLazyQuery();
  const [energyEmissionsLazyQuery] = useEnergyEmissionsLazyQuery();
  function fetchEnergyConsumption(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return energyConsumptionLazyQuery({
      variables: {
        input,
      },
    })
      .then(processSingleOperationGraphQLResponse("energyConsumption"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchEnergyProduction(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return energyProductionLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("energyProduction"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchEnergyStorage(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return energyStorageLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("energyStorage"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchTotalEnergyCapacity(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return totalEnergyCapacityLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("totalEnergyCapacity"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchTotalEnergyConsumption(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return totalEnergyConsumptionLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("totalEnergyConsumption"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchTotalEnergyProduction(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return totalEnergyProductionLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("totalEnergyProduction"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchTotalEnergyStorage(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return totalEnergyStorageLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("totalEnergyStorage"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchTotalGridEnergy(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return totalGridEnergyLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("totalGridEnergy"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchEnergyEmissions(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return energyEmissionsLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("energyEmissions"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  function fetchGridEnergy(input?: MetricQueryInput) {
    if (!input) {
      return Promise.reject(new Error("Input is required"));
    }
    return gridEnergyLazyQuery({
      variables: { input },
    })
      .then(processSingleOperationGraphQLResponse("gridEnergy"))
      .catch(processGraphQLOperationErrorsAndThrow);
  }

  return {
    fetchEnergyConsumption,
    fetchEnergyProduction,
    fetchEnergyStorage,
    fetchTotalEnergyCapacity,
    fetchTotalEnergyConsumption,
    fetchTotalEnergyProduction,
    fetchTotalEnergyStorage,
    fetchTotalGridEnergy,
    fetchEnergyEmissions,
    fetchGridEnergy,
  };
}
