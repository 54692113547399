// @ts-nocheck

import React, { useState, useEffect, useRef } from "react";
import ReactMapGL, { NavigationControl, MapRef, Source, Layer, Marker } from "react-map-gl";
import { bbox, featureCollection, point } from "@turf/turf"; // Import only what's needed
import { Button } from "@/edges/ui/Button";
import { Icon } from "@/edges/ui/Icon";
import { FeatureCollection, Point } from "geojson";
import { getResolvedColor } from "@/edges/utils";

// Mapbox configuration
const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

// Styles
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "~/tailwind.config";
const twTheme = resolveConfig(tailwindConfig).theme;
import "mapbox-gl/dist/mapbox-gl.css";
export const MAPBOX_THEMES = {
  dark: "mapbox://styles/victor-texture/clzwxtrng005s01p93rtr04op",
  light: "mapbox://styles/victor-texture/cm0bfrep900sf01psbg54aay4"
};
import { useColorMode } from "@/edges/utils/ColorModeProvider";

// Types
interface InteractiveMapProps {
  data: Array<{
    id: string;
    latitude: number;
    longitude: number;
  }>;
  onPointClick?: (pointId: string) => void;
  drawerComponent?: React.ReactNode;
  scrollEnabled?: boolean;
  className?: string;
}

// Define a type for the selected point
type SelectedPoint = {
  properties: {
    id: string;
  };
  geometry: {
    coordinates: [number, number];
  };
  // Add other fields if necessary
};
const InteractiveMap = ({
  data,
  onPointClick,
  drawerComponent,
  scrollEnabled = true,
  className
}: InteractiveMapProps) => {
  // Element refs
  const containerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapRef | null>(null);

  // Detect light or dark mode
  const {
    colorMode
  } = useColorMode();

  // View state for first render
  const [initialViewState, setInitialViewState] = useState<{
    latitude: number;
    longitude: number;
    zoom: number;
    bounds: [number, number, number, number];
  }>(undefined);

  // Control map camera with Mapbox methods, but track the current view state here
  const [viewState, setViewState] = useState<{
    latitude: number;
    longitude: number;
    zoom: number;
    bounds: [number, number, number, number];
  }>(undefined);

  // GeoJSON data
  const [geoJsonData, setGeoJsonData] = useState<FeatureCollection<Point, {
    id: string;
  }> | null>(null);

  // Reset zoom button
  const [showResetButton, setShowResetButton] = useState(false);

  // Selected point
  const [selectedPoint, setSelectedPoint] = useState<SelectedPoint | null>(null);
  const [selectedPointCoordinates, setSelectedPointCoordinates] = useState<[number, number] | null>(null);

  // Function to create GeoJSON data
  const createGeoJsonData = () => {
    return featureCollection(data.map(({
      longitude,
      latitude,
      id
    }) => point([longitude, latitude], {
      id
    })));
  };

  // Function to fit map bounds
  const fitMapBounds = (geoJsonData: FeatureCollection) => {
    const bounds = bbox(geoJsonData);
    if (mapRef.current) {
      mapRef.current.fitBounds([[bounds[0], bounds[1]],
      // Southwest coordinates
      [bounds[2], bounds[3]] // Northeast coordinates
      ], {
        padding: 50,
        duration: 1000
      });
    }
  };

  // Flag to track if initial map load has occurred
  const hasInitiallyLoaded = useRef(false);

  // Handle initial map load
  const handleMapLoad = () => {
    console.log("map load");
    if (mapRef.current) {
      const geoJsonData = createGeoJsonData();
      setGeoJsonData(geoJsonData);
      fitMapBounds(geoJsonData);
    }
  };

  // Function to update GeoJSON data
  const updateGeoJsonData = (newData: Array<{
    longitude: number;
    latitude: number;
    id: string;
  }>) => {
    const updatedGeoJsonData = featureCollection(newData.map(({
      longitude,
      latitude,
      id
    }) => point([longitude, latitude], {
      id
    })));
    setGeoJsonData(updatedGeoJsonData as FeatureCollection<Point, {
      id: string;
    }>);

    // Update the map source data directly
    const mapboxSource = mapRef.current?.getSource("locations") as mapboxgl.GeoJSONSource;
    if (mapboxSource) {
      mapboxSource.setData(updatedGeoJsonData);
    }
  };

  // Use effect to handle data updates
  useEffect(() => {
    if (hasInitiallyLoaded.current) {
      if (mapRef.current) {
        console.log("data update");
        updateGeoJsonData(data);
      }
    } else {
      hasInitiallyLoaded.current = true;
    }
  }, [data]); // Dependency on `data` prop

  // Callback for map movement end
  const handleMoveEnd = () => {
    // Set the inital view state
    if (!initialViewState) {
      setInitialViewState(viewState);
    } else {
      // Helper method to check if the view state has changed significantly from its initial position
      const isSignificantlyDifferent = (a, b, threshold = 1) => {
        return Math.abs(a - b) > threshold;
      };
      const {
        latitude: initLat,
        longitude: initLng,
        zoom: initZoom
      } = initialViewState;
      const {
        latitude: currLat,
        longitude: currLng,
        zoom: currZoom
      } = viewState;
      if (isSignificantlyDifferent(initLat, currLat) || isSignificantlyDifferent(initLng, currLng) || isSignificantlyDifferent(initZoom, currZoom)) {
        setShowResetButton(true);
      } else {
        setShowResetButton(false);
      }
    }
  };

  // Reset zoom to initial view state on button press
  const handleResetZoom = () => {
    if (mapRef.current && initialViewState) {
      mapRef.current.flyTo({
        center: [initialViewState.longitude, initialViewState.latitude],
        zoom: initialViewState.zoom,
        speed: 5,
        curve: 1
      });
    }
  };

  // Constants for clustering behavior
  const CLUSTER_THRESHOLD = 14; // Zoom level at which clustering stops
  const CLUSTER_RADIUS = 50; // Radius in pixels for clustering

  // Combined cluster configuration
  const CLUSTER_CONFIG = [{
    threshold: 30,
    radius: 20,
    color: twTheme.colors.blue[100]
  },
  // Small clusters
  {
    threshold: 50,
    radius: 30,
    color: twTheme.colors.blue[200]
  },
  // Medium clusters
  {
    threshold: 100,
    radius: 40,
    color: twTheme.colors.blue[400]
  } // Large clusters
  ];
  const CLUSTER_SHADOW_SCALE_FACTOR = 1.25; // Scale factor for shadow size

  // Helper methods to calculate cluster radius and shadow size
  const clusterRadiusExpression = ["step", ["get", "point_count"], CLUSTER_CONFIG[0].radius,
  // Base radius
  ...CLUSTER_CONFIG.flatMap(({
    threshold,
    radius
  }) => [threshold, radius])];
  const shadowRadiusExpression = ["step", ["get", "point_count"], CLUSTER_CONFIG[0].radius * CLUSTER_SHADOW_SCALE_FACTOR,
  // Base shadow radius
  ...CLUSTER_CONFIG.flatMap(({
    threshold,
    radius
  }) => [threshold, radius * CLUSTER_SHADOW_SCALE_FACTOR])];

  // Shadow layer for clusters
  const clusterShadowLayer = {
    id: "cluster-shadows",
    type: "circle",
    source: "locations",
    filter: ["has", "point_count"],
    // Only clusters
    layout: {},
    paint: {
      "circle-radius": shadowRadiusExpression,
      // Shadow size
      "circle-color": "rgba(0, 0, 0, 0.4)",
      // Simulated shadow color
      "circle-blur": 0.5,
      // Slight blur for softer edges
      "circle-translate": [0, 8] // Slight vertical offset
    }
  };
  // Cluster circle layer with color expression
  const clusterCircleLayer = {
    id: "clusters",
    type: "circle",
    source: "locations",
    filter: ["has", "point_count"],
    paint: {
      "circle-radius": clusterRadiusExpression,
      "circle-color": ["step", ["get", "point_count"], CLUSTER_CONFIG[0].color,
      // Base color
      ...CLUSTER_CONFIG.flatMap(({
        threshold,
        color
      }) => [threshold, color])]
    }
  };
  // Text layer for cluster count
  const clusterCountLayer = {
    id: "cluster-count",
    type: "symbol",
    source: "locations",
    filter: ["has", "point_count"],
    // Only clusters
    layout: {
      "text-field": "{point_count}",
      // Render the cluster count
      "text-font": ["Inter Bold"],
      // Bold text for visibility
      "text-size": 14,
      "text-offset": [0, 0.1] // Slight vertical offset
    },
    paint: {
      "text-color": getResolvedColor("text.body") // Main text color
    }
  };
  // Individual point layer (non-clustered)
  const unclusteredPointLayer = {
    id: "unclustered-points",
    type: "circle",
    source: "locations",
    filter: ["all", ["!", ["has", "point_count"]],
    // Only individual points
    ["!=", ["get", "id"], selectedPoint ? selectedPoint.properties.id : "selected"] // Exclude the selected point
    ],
    paint: {
      "circle-radius": 7,
      "circle-color": getResolvedColor("neutral.black"),
      // Point color
      "circle-stroke-width": 2,
      "circle-stroke-color": getResolvedColor("neutral.white")
    }
  };
  // Layer for the selected point pin
  const selectedPointLayer = {
    id: "selected-point",
    type: "symbol",
    source: "locations",
    filter: ["==", ["get", "id"], "selected"],
    // Filter for the selected point
    layout: {
      "icon-image": "pin-icon",
      // Use a pin icon
      "icon-size": 1.5,
      // Adjust size as needed
      "icon-offset": [0, -15] // Offset to position the pin correctly
    }
  };

  // Render a cursor pointer when hovering over interactive layers
  const handleMouseEnter = () => {
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = "pointer";
    }
  };
  // Render a default cursor when not hovering over interactive layers
  const handleMouseLeave = () => {
    if (mapRef.current) {
      mapRef.current.getCanvas().style.cursor = "";
    }
  };
  const DRAWER_WIDTH = 400;

  // General handler for all map clicks
  const handleMapClick = (event: any) => {
    const features = event.features;
    if (features && features.length > 0) {
      const clickedFeature = features[0];
      if (clickedFeature.properties.cluster) {
        handleClusterClick(clickedFeature);
      } else {
        handlePointClick(event);
      }
    } else {
      setSelectedPoint(null);
      setSelectedPointCoordinates(null);
    }
  };
  // Handle click events on points
  const handlePointClick = (event: any) => {
    const feature = event.features[0];
    if (feature) {
      const {
        coordinates
      } = feature.geometry;
      setSelectedPoint(feature);
      setSelectedPointCoordinates(coordinates);

      // Notify parent of the point click
      if (onPointClick) {
        onPointClick(feature.properties.id);
      }

      // Adjust the map to center on the clicked point, offset by drawer width
      if (mapRef.current) {
        mapRef.current.flyTo({
          center: coordinates,
          zoom: 14,
          offset: [-DRAWER_WIDTH / 2, 0],
          speed: 8,
          curve: 1
        });
      }
    }
  };
  // Handle click events on clusters
  const handleClusterClick = (clickedFeature: any) => {
    const clusterId = clickedFeature.properties.cluster_id;
    const mapboxSource = mapRef.current?.getSource("locations");
    if (mapboxSource) {
      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) return;
        const coordinates = clickedFeature.geometry.coordinates;
        if (mapRef.current) {
          mapRef.current.flyTo({
            center: coordinates,
            zoom: zoom,
            speed: 2.5,
            curve: 1
          });
        }
      });
    }
  };

  // Function to close the drawer
  const handleCloseDrawer = () => {
    setSelectedPoint(null);
    setSelectedPointCoordinates(null);
  };
  return <div ref={containerRef} className={`relative flex h-full w-full overflow-hidden ${className}`} data-sentry-component="InteractiveMap" data-sentry-source-file="InteractiveMap.tsx">
      <div style={{
      flex: 1
    }}>
        <ReactMapGL ref={mapRef} initialViewState={initialViewState} style={{
        width: "100%",
        height: "100%"
      }} mapStyle={MAPBOX_THEMES.light} mapboxAccessToken={MAPBOX_TOKEN} onLoad={handleMapLoad} onMove={evt => setViewState(evt.viewState)} onMoveEnd={handleMoveEnd} onClick={handleMapClick} interactiveLayerIds={["unclustered-points", "clusters"]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} scrollZoom={scrollEnabled} data-sentry-element="ReactMapGL" data-sentry-source-file="InteractiveMap.tsx">
          <NavigationControl position="top-right" data-sentry-element="NavigationControl" data-sentry-source-file="InteractiveMap.tsx" />

          {/* Add the GeoJSON source and layer if data is available */}
          {geoJsonData && <Source id="locations" type="geojson" data={geoJsonData} cluster={true} clusterMaxZoom={CLUSTER_THRESHOLD} clusterRadius={CLUSTER_RADIUS}>
              {/* Shadow layer for clusters */}
              <Layer {...clusterShadowLayer} />
              {/* Main cluster circles */}
              <Layer {...clusterCircleLayer} />
              {/* Cluster count labels */}
              <Layer {...clusterCountLayer} />
              {/* Individual points */}
              <Layer {...unclusteredPointLayer} />
              {/* Selected point pin */}
              {selectedPointCoordinates && <Layer {...selectedPointLayer} layout={{
            "icon-image": "pin-icon",
            "icon-size": 36,
            "icon-offset": [0, -15]
          }} filter={["==", ["get", "id"], "selected"]} />}
            </Source>}
          {selectedPointCoordinates && <Marker latitude={selectedPointCoordinates[1]} longitude={selectedPointCoordinates[0]}>
              <Icon name="MapPin" className="relative bottom-6 left-[5px]" size={40} color={getResolvedColor("text.body")} weight="fill" />
            </Marker>}
        </ReactMapGL>
      </div>

      {/* Reset Zoom Button */}
      {showResetButton && <Button className="z-15 absolute bottom-15 right-15 bg-white shadow-xl" onPress={handleResetZoom} variant="secondary" size="lg">
          Reset Zoom
        </Button>}

      {/* Drawer */}
      {selectedPoint && <div className={`absolute right-0 top-0 z-10 flex h-full flex-col w-[${DRAWER_WIDTH}px] translate-x-0 overflow-y-auto bg-white p-5 shadow-3xl transition-transform duration-300 ease-in-out animate-in slide-in-from-right`}>
          {/* Close Icon */}
          <div className="flex h-10 w-full justify-end">
            <div className="cursor-pointer" onClick={handleCloseDrawer}>
              <Icon name="X" size={24} color={getResolvedColor("text.body")} />
            </div>
          </div>
          {drawerComponent && drawerComponent}
        </div>}
    </div>;
};
export { InteractiveMap };