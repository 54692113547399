import { DateTime } from "luxon";
import { composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";

export { ColorModeProvider, useColorMode } from "./ColorModeProvider";

export const formatIsoDateString = (isoDateString: string) => {
  const dateTime = DateTime.fromISO(isoDateString);

  return {
    toFullDateTime() {
      return dateTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
    },
    toRelative() {
      return dateTime.toRelative();
    },
  };
};

export function focusRing() {
  const baseClasses =
    "forced-colors:outline-[Highlight] outline-1 outline-gray-300 focus:outline-2 focus:outline-border-focus focus:outline-offset-0";
  return `${baseClasses}`;
}

export function composeTailwindRenderProps<T>(
  className: string | ((v: T) => string) | undefined,
  tw: string,
): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}

export function toTitleCase(text: string): string {
  if (!text) return text;
  const articles = new Set([
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "for",
    "nor",
    "on",
    "at",
    "to",
    "with",
  ]);
  return text
    .toLocaleLowerCase()
    .split(/\s+/)
    .map((word, index) =>
      articles.has(word) && index !== 0
        ? word
        : word.charAt(0).toLocaleUpperCase() + word.slice(1),
    )
    .join(" ");
}

export function truncateMiddle(value: string, length: number = 16): string {
  if (!value) return value;
  if (value.length <= length) return value;
  const mid = Math.floor(length / 2);
  return `${value.slice(0, mid)}...${value.slice(-mid)}`;
}

export function toSentenceCase(text: string): string {
  if (!text) return text;
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}

export function formatPhoneNumber(phone: string): string {
  if (!phone) return phone;
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export function toDateString(date: string, showTime: boolean = false): string {
  if (!date) return date;

  // Normalize the date string to ensure uppercase "T" and "Z"
  const normalizedDate = date.replace("t", "T").replace("z", "Z");

  const dt = DateTime.fromISO(normalizedDate); // Parse the ISO date string

  if (!dt.isValid) {
    return "Invalid Date";
  }

  return showTime
    ? dt.toLocaleString(DateTime.DATETIME_SHORT) // Includes both date and time
    : dt.toLocaleString(DateTime.DATE_SHORT); // Date only
}

const colorCache = new Map<string, string>();

export const getResolvedColor = (variableName: string): string => {
  // Early return if running in non-browser environment
  if (typeof window === "undefined") return "";

  // Return cached value if available
  if (colorCache.has(variableName)) {
    return colorCache.get(variableName)!;
  }

  try {
    const tempElement = document.createElement("div");
    tempElement.style.color = `var(${variableName})`;
    document.body.appendChild(tempElement);
    const resolvedColor = getComputedStyle(tempElement).color;
    document.body.removeChild(tempElement);
    // Cache the result
    colorCache.set(variableName, resolvedColor);
    return resolvedColor;
  } catch (error) {
    console.error(`Failed to resolve color for ${variableName}:`, error);
    return "";
  }
};
