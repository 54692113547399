import React from "react";
import { Card } from "@/edges/ui/Card";
import { Heading } from "@/edges/ui/Heading";
interface MetricCardProps {
  title?: string;
  value: string | number;
  unit?: string;
  trend?: {
    value: number;
    label?: string;
  };
  icon?: React.ReactNode;
  iconColor?: string;
  isGhost?: boolean;
  className?: string;
}
export const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  unit,
  trend,
  icon,
  isGhost = false,
  className
}) => {
  const getTrendDirection = (value: number) => {
    if (value > 0) return "positive";
    if (value < 0) return "negative";
    return "neutral";
  };
  const getTrendClasses = (direction: string | null) => {
    switch (direction) {
      case "positive":
        return {
          backgroundColor: "bg-green-100",
          textColor: "text-green-600",
          symbol: "↑"
        };
      case "negative":
        return {
          backgroundColor: "bg-red-100",
          textColor: "text-red-600",
          symbol: "↓"
        };
      case "neutral":
        return {
          backgroundColor: "bg-gray-100",
          textColor: "text-text-caption",
          symbol: " "
        };
      default:
        return {
          backgroundColor: "",
          textColor: "",
          symbol: ""
        };
    }
  };
  const trendDirection = trend ? getTrendDirection(trend.value) : null;
  const trendClasses = getTrendClasses(trendDirection);
  const trendBackgroundColor = trendClasses.backgroundColor || "";
  return <Card isGhost={isGhost} className={className} data-sentry-element="Card" data-sentry-component="MetricCard" data-sentry-source-file="MetricCard.tsx">
      <div className="flex h-full flex-col pb-4">
        {title && <Heading size="xs">{title}</Heading>}
        <div className="flex flex-grow">
          <div className="flex flex-col">
            <div className="flex min-h-12 md:min-h-14">
              <div className="flex flex-col justify-end text-headingMd">
                <div>{value}</div>
              </div>
              {unit && <div className="ml-2 flex flex-col justify-end text-headingMd">
                  {unit}
                </div>}
            </div>
            {trend && <div className="mt-2 flex items-center">
                <div className={`inline-flex items-center rounded-full px-2 py-0.5 text-sm ${trendClasses.backgroundColor} ${trendClasses.textColor}`}>
                  {trendClasses.symbol} {Math.abs(trend.value)}%
                </div>
                {trend.label && <span className="ml-2 text-sm text-text-caption">
                    {trend.label}
                  </span>}
              </div>}
          </div>
          {icon && <div className={`rounded-full p-2 ${trendBackgroundColor}`}>
              <div className={`${trendClasses.textColor}`}>{icon}</div>
            </div>}
        </div>
      </div>
    </Card>;
};