import React from "react";
import { AxisLeft as VisxAxisLeft } from "@visx/axis";
import { getYFormatSettings, YFormatType } from "@/edges/utils/charts";
import { useTailwindTheme } from "@/hooks";
import { ScaleLinear } from "d3-scale";
interface AxisLeftProps {
  left: number;
  scale: ScaleLinear<number, number>;
  yFormatType: YFormatType;
}
const AxisLeft: React.FC<AxisLeftProps> = ({
  left,
  scale,
  yFormatType
}) => {
  const {
    getThemeValue
  } = useTailwindTheme();
  const borderColor = getThemeValue("colors.border.default")?.toString() || "#E5E7EB";
  const textColor = getThemeValue("colors.text.caption")?.toString() || "#374151";
  return <VisxAxisLeft left={left} scale={scale} numTicks={5} stroke={borderColor} tickStroke={borderColor} tickFormat={value => {
    const numValue = Number(value);
    return getYFormatSettings(yFormatType).tickFormat?.(numValue) || numValue.toString();
  }} tickLabelProps={() => ({
    className: "font-body text-text-caption text-xs",
    fill: textColor,
    textAnchor: "end",
    dx: "-0.75em",
    dy: "0.275em"
  })} data-sentry-element="VisxAxisLeft" data-sentry-component="AxisLeft" data-sentry-source-file="AxisLeft.tsx" />;
};
export { AxisLeft };