// @ts-nocheck

"use client";

import React from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Button } from "@/edges/ui/Button";
import { Gap } from "@/components/Layout";
import { Heading } from "@/edges/ui/Heading";
import { HomePageTabs } from "./HomePageTabs";
import { Text } from "@/components/Typography";
import { useTranslations } from "next-intl";
import { useNavigationService } from "@/services/navigationService";
export default function HomepageEmptyState(): React.ReactElement {
  const t = useTranslations("HomePage");
  const navigationService = useNavigationService();
  const isAppsEnabled = useFeatureIsOn("apps");
  return <>
      <Heading size="lg" data-sentry-element="Heading" data-sentry-source-file="HomepageEmptyState.tsx">{t("welcome")}</Heading>

      <Gap px={36} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />

      <HomePageTabs data-sentry-element="HomePageTabs" data-sentry-source-file="HomepageEmptyState.tsx">
        <HomePageTabs.Tab name={t("configureTextureConnect")} data-sentry-element="unknown" data-sentry-source-file="HomepageEmptyState.tsx">
          <Heading data-sentry-element="Heading" data-sentry-source-file="HomepageEmptyState.tsx">{t("textureConnect")}</Heading>
          <Gap rem={1.5} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
          <Text.DarkIndigo small data-sentry-element="unknown" data-sentry-source-file="HomepageEmptyState.tsx">{t("useFlowConnector")}</Text.DarkIndigo>
          <Gap rem={1.5} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
          <Button onPress={navigationService.navigateToSources} data-sentry-element="Button" data-sentry-source-file="HomepageEmptyState.tsx">
            {t("configureTextureConnect")}
          </Button>
        </HomePageTabs.Tab>

        <HomePageTabs.Tab name={t("useInstallerAPI")} data-sentry-element="unknown" data-sentry-source-file="HomepageEmptyState.tsx">
          <Heading data-sentry-element="Heading" data-sentry-source-file="HomepageEmptyState.tsx">{t("installerAPI")}</Heading>
          <Gap rem={1.5} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
          <Text.DarkIndigo small data-sentry-element="unknown" data-sentry-source-file="HomepageEmptyState.tsx">
            {t("ifDeviceInstallerYouCanUseAPI")}
          </Text.DarkIndigo>
          <Gap rem={1.5} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
          <Button onPress={navigationService.navigateToSourcesInstallerAccess} data-sentry-element="Button" data-sentry-source-file="HomepageEmptyState.tsx">
            {t("startWithInstallerAPI")}
          </Button>
        </HomePageTabs.Tab>

        <HomePageTabs.Tab name={t("integrateUtilityProvider")} data-sentry-element="unknown" data-sentry-source-file="HomepageEmptyState.tsx">
          <Heading data-sentry-element="Heading" data-sentry-source-file="HomepageEmptyState.tsx">{t("integrateUtilityProvider")}</Heading>
          <Gap rem={1.5} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
          <Text.DarkIndigo small data-sentry-element="unknown" data-sentry-source-file="HomepageEmptyState.tsx">
            {t("ifUtilityProviderConnect")}
          </Text.DarkIndigo>
          <Gap rem={1.5} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
          <Button onPress={isAppsEnabled ? navigationService.navigateToApps : navigationService.navigateToSourcesUtilityData} data-sentry-element="Button" data-sentry-source-file="HomepageEmptyState.tsx">
            {t("connectAccountToTexture")}
          </Button>
        </HomePageTabs.Tab>
      </HomePageTabs>

      <Gap px={56} data-sentry-element="Gap" data-sentry-source-file="HomepageEmptyState.tsx" />
    </>;
}