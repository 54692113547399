// @ts-nocheck

"use client";

import { useRef, useState, useEffect, Children, ReactElement } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { notEmpty } from "@9yco/utils.utils";
import React from "react";

export enum TabsVariant {
  Chart = "Chart",
  Default = "Default",
}

export type TabsProps = {
  title?: string;
  children?: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
  tabs?: TabProps[];
  withRouter?: boolean;
  onChange?(selectedTab: number): void;
  variant?: TabsVariant;
};

export interface TabProps {
  name: string;
  children: React.ReactNode;
  isSelected?: boolean;
  lazyLoaded?: boolean;
}

interface UseTabs {
  tabs: TabProps[];
  selectTab(tab: TabProps): void;
  shouldNotRenderTab(tab: TabProps): boolean;
  isTabSelected(tab: TabProps): boolean;
}
export default function useTabs(props: TabsProps): UseTabs {
  const router = useRouter();
  const search = useSearchParams();
  const pathname = usePathname();

  const loadedTabsMap = useRef(new Map());

  const tabs = notEmpty(props.tabs)
    ? props.tabs
    : Children.toArray(props.children).map(
        (child: ReactElement<TabProps>) => child.props,
      );

  const [selectedTab, setSelectedTab] = useState(getDefaultSelectedTab());

  function getDefaultSelectedTab() {
    let selectedIndex = undefined;

    function getDefaultIndex(selectedIndex: number) {
      return selectedIndex === -1 ? 0 : selectedIndex;
    }

    if (props.withRouter) {
      selectedIndex = tabs?.findIndex(isTabSetInURL);
    } else {
      selectedIndex = tabs?.findIndex(tabHasIsSelected);
    }

    setTabAsRendered(tabs?.[getDefaultIndex(selectedIndex!)]!);

    return getDefaultIndex(selectedIndex!);
  }

  useEffect(() => {
    if (props.withRouter) {
      const isTabSetToURL = tabs?.some(isTabSetInURL);

      if (isTabSetToURL === false) {
        setTabToURL(tabs?.[0]!);
      }
    }
  }, [props.withRouter]);

  function isTabSetInURL(tab: TabProps) {
    return search.get("tab") === tab.name;
  }
  function tabHasIsSelected(tab: TabProps) {
    return tab.isSelected;
  }

  function selectTab(tab: TabProps) {
    if (props.withRouter) {
      setTabToURL(tab);
    }

    setSelectedTab(() => {
      const newIndex = tabs?.findIndex(({ name }) => name === tab.name) ?? 0;
      props.onChange?.(newIndex!);
      return newIndex === -1 ? 0 : newIndex;
    });

    setTabAsRendered(tab);
  }

  function setTabAsRendered(tab: TabProps) {
    loadedTabsMap.current.set(tab.name, new Date().toISOString());
  }

  const setTabToURL = React.useCallback(
    (tab: TabProps) => {
      const params = new URLSearchParams(search.toString());
      params.set("tab", tab.name);
      router.push(pathname + "?" + params.toString());
    },
    [search, router],
  );

  const shouldNotRenderTab = React.useCallback(
    (tab: TabProps) =>
      Boolean(tab.lazyLoaded && loadedTabsMap.current.has(tab.name) === false),
    [loadedTabsMap.current],
  );

  const isTabSelected = React.useCallback(
    (tab: TabProps) =>
      selectedTab === tabs?.findIndex(({ name }) => name === tab.name),
    [selectedTab],
  );

  return {
    tabs: tabs ?? [],
    selectTab,
    shouldNotRenderTab,
    isTabSelected,
  };
}
