import React from "react";
import { useTranslations } from "next-intl";
import { Event } from "@/@codegen/supergraph";
import { Card } from "@/edges/ui/Card";
import { ActivityFeedEvent } from "./ActivityFeedEvent";
export interface ActivityFeedProps {
  events: Event[];
  isLoading?: boolean;
}
const ActivityFeedLoading = () => {
  return <>
      {[...Array(3)].map((_, index) => <div key={index} className="flex animate-pulse items-start space-x-4 border-b border-gray-100 pb-4 last:border-b-0">
          <div className="flex-shrink-0">
            <div className="h-10 w-10 rounded-full bg-gray-200" />
          </div>
          <div className="min-w-0 flex-1">
            <div className="mb-2 h-4 w-1/4 rounded bg-gray-200" />
            <div className="mb-2 h-3 w-3/4 rounded bg-gray-200" />
            <div className="h-2 w-1/4 rounded bg-gray-200" />
          </div>
        </div>)}
    </>;
};
const ActivityFeedEmpty = () => {
  const t = useTranslations();
  return <div className="py-6 text-center text-text-caption" data-sentry-component="ActivityFeedEmpty" data-sentry-source-file="index.tsx">
      {t("device.noEvents")}
    </div>;
};
export const ActivityFeed = ({
  events,
  isLoading
}: ActivityFeedProps) => {
  const t = useTranslations();
  return <Card className="p-4" heading={t("device.activityFeed")} data-sentry-element="Card" data-sentry-component="ActivityFeed" data-sentry-source-file="index.tsx">
      <div className="flex flex-col space-y-4">
        {isLoading ? <ActivityFeedLoading /> : events.length === 0 ? <ActivityFeedEmpty /> : events.map((event, index) => {
        return <ActivityFeedEvent key={index} event={event} index={index} />;
      })}
      </div>
    </Card>;
};