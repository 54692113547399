// @ts-nocheck

import React from "react";
import If from "@/components/If/If";
import { ArrowRight } from "@/assets/icons/ArrowRight";
import { Text } from "@/components/Typography";
import { classNames } from "@/utils";
export function Grid(props: React.PropsWithChildren) {
  const className = classNames("bg-gray-lightest border border-gray-medium rounded", "grid grid-cols-1 sm:grid-cols-2 gap-12 p-8");
  return <div className={className} data-sentry-component="Grid" data-sentry-source-file="style.tsx">{props.children}</div>;
}
Grid.Column = function Column(props: React.PropsWithChildren) {
  return <div className="grid gap-y-4">{props.children}</div>;
};
export function Header(props: {
  label: string;
  isSelected?: boolean;
  onClick: React.MouseEventHandler;
}) {
  const className = classNames(props.isSelected && "bg-white", "border border-gray-medium rounded-[3px] py-[6px] px-[12px]", "flex flex-row items-center justify-start", "cursor-pointer");
  return <div className={className} onClick={props.onClick} data-sentry-component="Header" data-sentry-source-file="style.tsx">
      <If condition={props.isSelected} data-sentry-element="If" data-sentry-source-file="style.tsx">
        <If.Then data-sentry-element="unknown" data-sentry-source-file="style.tsx">
          <ArrowRight className="mr-[8px]" data-sentry-element="ArrowRight" data-sentry-source-file="style.tsx" />
        </If.Then>
        <If.Else data-sentry-element="unknown" data-sentry-source-file="style.tsx">
          <div className="h-[24px] w-[24px]" />
        </If.Else>
      </If>

      <div className="pt-[2px]">
        <Text.DarkIndigo data-sentry-element="unknown" data-sentry-source-file="style.tsx">{props.label}</Text.DarkIndigo>
      </div>
    </div>;
}
export function Content(props: React.PropsWithChildren<{
  isSelected?: boolean;
}>) {
  const className = classNames(props.isSelected ? "block" : "hidden");
  return <div className={className} data-sentry-component="Content" data-sentry-source-file="style.tsx">{props.children}</div>;
}