"use client";

import { ColorModeProvider } from "./ColorModeProvider";
import TrackingProvider from "./TrackingProvider";
export function ClientProviders({
  children
}: {
  children: React.ReactNode;
}) {
  return <ColorModeProvider data-sentry-element="ColorModeProvider" data-sentry-component="ClientProviders" data-sentry-source-file="ClientProviders.tsx">
      <TrackingProvider data-sentry-element="TrackingProvider" data-sentry-source-file="ClientProviders.tsx">{children}</TrackingProvider>
    </ColorModeProvider>;
}