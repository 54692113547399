// @ts-nocheck

"use client";

import { isNil } from "lodash";

import { CreateOrganizationInput as RepositoryCreateOrganizationInput } from "@/@codegen/supergraph";

import { useAuthenticationService } from "@/services/authenticationService";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";

import {
  UpdateOrganizationLocationInput,
  useOrganizationRepository,
} from "@/repositories/organizationRepository";

export function useOrganizationService() {
  const authenticationService = useAuthenticationService();
  const organizationRepository = useOrganizationRepository();

  const { isOrganizationInLoggedUserMemberships } = useLoggedUserFunctions();

  async function switchOrganizationSession(organizationId: string) {
    if (isOrganizationInLoggedUserMemberships(organizationId) === false) {
      throw new Error(
        "Cannot switch to organization because organization is not in logged user memberships.",
      );
    }

    return authenticationService.refreshSession(organizationId);
  }

  async function createOrganization(input: CreateOrganizationInput) {
    // Hack for now until we figure out if this is ok https://github.com/TextureHQ/mono/blob/771da346e64a45856781a52a990cb0af5e75af3a/apps/dash/web/src/pages/OnboardingOrgPage/hooks/use-onboarding-org/index.ts#L63
    if (isNil(input.domain)) {
      input.domain = input.name;
    }

    const { country, ...createOrganizationInput } = input;

    // We merge to repository methods in one service call
    const newOrganization = await organizationRepository.createOrganization(
      createOrganizationInput as RepositoryCreateOrganizationInput,
    );

    if (country) {
      await organizationRepository.updateOrganizationLocation({
        id: newOrganization.id,
        country,
      });
    }

    return newOrganization;
  }

  async function deleteMembership(id: string, organizationId?: string) {
    return organizationRepository.deleteMembership(id, organizationId);
  }

  return { switchOrganizationSession, createOrganization, deleteMembership };
}

export type CreateOrganizationInput = Omit<
  RepositoryCreateOrganizationInput,
  "domain"
> &
  Pick<UpdateOrganizationLocationInput, "country"> &
  Partial<Pick<RepositoryCreateOrganizationInput, "domain">>;
