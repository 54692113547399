"use client";

import Head from "next/head";
import { useState } from "react";
import { useNavigationService } from "@/services/navigationService";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import { useSelectedWorkspace } from "@/state/user/useSelectedWorkspace";
import { useSelectedOrganization } from "@/state/user/useSelectedOrganization";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import { AppLayout } from "@/edges/layouts/AppLayout";
import { UserMenu } from "@/edges/ui/UserMenu";
import { WorkspaceSwitcher, Workspace } from "@/edges/ui/WorkspaceSwitcher";
import { useSidebarLinks } from "@/common/navigation";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { useColorMode } from "@/providers/ColorModeProvider";
export default function DashboardLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const {
    links
  } = useSidebarLinks();
  const {
    isDarkTheme,
    toggleTheme
  } = useColorMode();
  const {
    loggedUser
  } = useLoggedUser();
  const {
    isRouteUnderIdFolder,
    getPrimaryRoute,
    navigateTo
  } = useNavigationService();
  const selectedLink = getPrimaryRoute();
  const {
    selectedWorkspace,
    setSelectedWorkspace
  } = useSelectedWorkspace();
  const handleWorkspaceChange = (workspaceOrId: string | Workspace) => {
    setSelectedWorkspace(typeof workspaceOrId === "string" ? {
      id: workspaceOrId
    } as Workspace : workspaceOrId);
    if (isRouteUnderIdFolder()) {
      navigateTo(getPrimaryRoute());
    }
  };
  const {
    selectedOrganization
  } = useSelectedOrganization();
  const {
    isAdminOfOrganization
  } = useLoggedUserFunctions();
  const isThemeToggleEnabled = useFeatureIsOn("theme-toggle");
  if (!loggedUser || !selectedWorkspace) {
    return null;
  } else {
    return <>
        <Head>
          <link rel="preconnect" href="https://gateway.texture.energy" />
          <link rel="preconnect" href="https://cdn.filestackcontent.com" />
        </Head>
        <AppLayout selectedLink={selectedLink} links={links} helpLink="https://docs.texturehq.com/docs/home/welcome" userMenu={<UserMenu />} showThemeToggle={isThemeToggleEnabled} isDarkThemeEnabled={isDarkTheme} toggleTheme={toggleTheme} workspaceSwitcher={<WorkspaceSwitcher workspaces={selectedOrganization!.workspaces ?? []} currentWorkspace={selectedWorkspace} onWorkspaceChange={handleWorkspaceChange} canAddWorkspace={isAdminOfOrganization(selectedWorkspace.organizationId)} />}>
          {children}
        </AppLayout>
      </>;
  }
}