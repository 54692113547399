// @ts-nocheck

import React from "react";
import { classNames } from "@/utils";
export function Container(props: React.PropsWithChildren<{
  className?: string;
}>) {
  const className = classNames("w-full h-full bg-transparent flex flex-col items-center justify-center", props.className);
  return <div className={className} data-sentry-component="Container" data-sentry-source-file="styles.tsx">{props.children}</div>;
}
export function ContentSection(props: React.PropsWithChildren) {
  return <div className="flex flex-col items-center justify-center bg-white p-[60px] sm:p-[160px]" style={{
    boxShadow: "0px 4px 20px 0px rgba(86, 77, 107, 0.20)"
  }} data-sentry-component="ContentSection" data-sentry-source-file="styles.tsx">
      <div className="flex max-w-[357px] flex-col items-center justify-center gap-y-[24px] text-center">
        {props.children}
      </div>
    </div>;
}