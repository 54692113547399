import React from "react";
import { RequireOnlyOne } from "@9yco/utils.utils/types";
import { useHeading } from "@/components/Typography/Heading/hooks/useHeading";
export type HeadingProps = HeadingSize & HeadingWeight & HeadingOverflow & HeadingColor & {
  alignCenter?: boolean;
  upperCase?: boolean;
};
type HeadingSize = RequireOnlyOne<{
  extraLarge: boolean;
  large: boolean;
  small: boolean;
  extraSmall: boolean;
  extraExtraSmall: boolean;
}> & OnMobileHeadingSize & OnTabletHeadingSize;
type OnMobileHeadingSize = RequireOnlyOne<{
  "onMobile:extraLarge": boolean;
  "onMobile:large": boolean;
  "onMobile:small": boolean;
  "onMobile:regular": boolean;
  "onMobile:extraSmall": boolean;
  "onMobile:extraExtraSmall": boolean;
}>;
type OnTabletHeadingSize = RequireOnlyOne<{
  "onTablet:extraLarge": boolean;
  "onTablet:large": boolean;
  "onTablet:small": boolean;
  "onTablet:regular": boolean;
  "onTablet:extraSmall": boolean;
  "onTablet:extraExtraSmall": boolean;
}>;
type HeadingWeight = RequireOnlyOne<{
  semiBold: boolean;
  medium: boolean;
}>;
type HeadingColor = {
  color?: string;
};
type HeadingOverflow = {
  ellipsis?: boolean;
};
const headingDefaultProps: Partial<HeadingProps> = {};
function HeadingPennBlue(props: React.PropsWithChildren<HeadingProps>) {
  const headingProps = useHeading(props);
  return <span {...headingProps} data-sentry-component="HeadingPennBlue" data-sentry-source-file="index.tsx">{props.children}</span>;
}
export function HeadingGray(props: React.PropsWithChildren<HeadingProps>) {
  const headingProps = useHeading({
    ...props,
    color: "text-gray-darker"
  });
  return <span {...headingProps} data-sentry-component="HeadingGray" data-sentry-source-file="index.tsx">{props.children}</span>;
}
function HeadingWhite(props: React.PropsWithChildren<HeadingProps>) {
  const headingProps = useHeading(props, "text-white");
  return <span {...headingProps} data-sentry-component="HeadingWhite" data-sentry-source-file="index.tsx">{props.children}</span>;
}
function HeadingDarkIndigo(props: React.PropsWithChildren<HeadingProps>) {
  const headingProps = useHeading(props, "text-neutral-dark");
  return <span {...headingProps} data-sentry-component="HeadingDarkIndigo" data-sentry-source-file="index.tsx">{props.children}</span>;
}
function HeadingInputCount(props: React.PropsWithChildren<HeadingProps>) {
  const headingProps = useHeading(props, "text-brand-dark");
  return <span {...headingProps} data-sentry-component="HeadingInputCount" data-sentry-source-file="index.tsx">{props.children}</span>;
}
function HeadingError(props: React.PropsWithChildren<HeadingProps>) {
  const headingProps = useHeading(props, "text-feedback-error-text");
  return <span {...headingProps} data-sentry-component="HeadingError" data-sentry-source-file="index.tsx">{props.children}</span>;
}
export const Heading = {
  PennBlue: HeadingPennBlue,
  Gray: HeadingGray,
  White: HeadingWhite,
  DarkIndigo: HeadingDarkIndigo,
  InputCount: HeadingInputCount,
  Error: HeadingError
};