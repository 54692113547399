import React from "react";
import { Icon } from "@/edges/ui/Icon";
import { Tooltip } from "@/edges/ui/Tooltip";
import { Button } from "@/edges/ui/Button";
import { FieldErrorProps, Group, GroupProps, InputProps, LabelProps as RACLabelProps, FieldError as RACFieldError, Input as RACInput, Label as RACLabel, Text, TextProps, composeRenderProps } from "react-aria-components";
import { twMerge } from "tailwind-merge";
import { composeTailwindRenderProps, focusRing } from "@/edges/utils";
export interface LabelProps extends RACLabelProps {
  className?: string;
  tooltip?: string;
  isRequired?: boolean;
}
export function Label(props: LabelProps) {
  const {
    tooltip,
    isRequired,
    children,
    ...restProps
  } = props;
  return <div className="flex items-center gap-1" data-sentry-component="Label" data-sentry-source-file="Field.tsx">
      <RACLabel {...restProps} className={twMerge("w-fit cursor-default text-sm font-bold text-text-caption", props.className)} data-sentry-element="RACLabel" data-sentry-source-file="Field.tsx">
        {children}
        {isRequired && <span className="ml-1 text-lg font-medium text-text-caption">*</span>}
      </RACLabel>
      {tooltip && <Tooltip content={tooltip}>
          <Button variant="icon" className="pl-1 pr-0">
            <Icon name="Info" size={18} />
          </Button>
        </Tooltip>}
    </div>;
}
export function Description(props: TextProps) {
  return <Text {...props} slot="description" className={twMerge("text-sm text-gray-600", props.className)} data-sentry-element="Text" data-sentry-component="Description" data-sentry-source-file="Field.tsx" />;
}
export function FieldError(props: FieldErrorProps) {
  return <div className="min-h-6" data-sentry-component="FieldError" data-sentry-source-file="Field.tsx">
      <RACFieldError {...props} className={composeTailwindRenderProps(props.className, "text-sm text-destructive")} data-sentry-element="RACFieldError" data-sentry-source-file="Field.tsx" />
    </div>;
}
export function getFieldBorderStyles({
  isFocusWithin = false,
  isInvalid = false,
  isDisabled = false
} = {}) {
  const styles = [isFocusWithin ? "border-gray-600 dark:border-zinc-300 forced-colors:border-[Highlight]" : "border-gray-300 dark:border-zinc-500 forced-colors:border-[ButtonBorder]", isInvalid && "outline-red-600 dark:outline-red-600 outline-2", isDisabled && "border-gray-200 dark:border-zinc-700 forced-colors:border-[GrayText]"].filter(Boolean);
  return styles.join(" ");
}
export function getFieldGroupStyles(props: {
  isFocusWithin?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  className?: string;
}) {
  return twMerge(focusRing(), "group flex items-center h-9 bg-background-input  forced-colors:bg-[Field] border-2 rounded-lg overflow-hidden", getFieldBorderStyles(props), props.className);
}
export function FieldGroup(props: GroupProps) {
  return <Group {...props} className={composeRenderProps(props.className, (className, renderProps) => getFieldGroupStyles({
    ...renderProps,
    className
  }))} data-sentry-element="Group" data-sentry-component="FieldGroup" data-sentry-source-file="Field.tsx" />;
}
export function Input(props: InputProps) {
  return <RACInput {...props} className={composeTailwindRenderProps(props.className, "text-text-action-primary disabled:bg-background-disabled min-w-0 flex-1 bg-background-input px-2 py-0 outline outline-0 placeholder:text-placeholder")} data-sentry-element="RACInput" data-sentry-component="Input" data-sentry-source-file="Field.tsx" />;
}