"use client";

import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
export function setSentryUser() {
  const {
    getTrackingUserData
  } = useLoggedUserFunctions();
  const trackingUserData = getTrackingUserData();
  Sentry.setUser(trackingUserData);

  // Add LogRocket session URL to Sentry scope
  try {
    LogRocket.getSessionURL(sessionURL => {
      try {
        const scope = Sentry.getCurrentScope();
        if (scope && sessionURL) {
          scope.setExtra("logrocketSessionURL", sessionURL);
        }
      } catch (error) {
        console.error("Failed to set LogRocket session URL in Sentry:", error);
      }
    });
  } catch (error) {
    console.error("Failed to get LogRocket session URL:", error);
  }
}