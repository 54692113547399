import type { Config } from "tailwindcss";
import tailwindAnimate from "tailwindcss-animate";
import tailwindRAC from "tailwindcss-react-aria-components";
import newColors from "./src/styles/tailwind-colors.json";
import themev2 from "./src/styles/theme";

const themev3 = {
  colors: newColors,
  borderColor: {
    DEFAULT: newColors.border.default,
  },
};

interface ThemeObject {
  [key: string]: string | number | boolean | string[] | ThemeObject | undefined;
}

/**
 * Recursively merges multiple theme objects into a single theme configuration.
 *
 * @remarks
 * This function performs a deep merge of theme objects, preserving nested object structures
 * and overwriting values when necessary. It is specifically designed for merging Tailwind CSS theme configurations.
 *
 * @param base - The initial theme object to serve as the base for merging
 * @param objects - Additional theme objects to merge into the base theme
 * @returns A new theme object that combines all input theme objects
 *
 * @example
 * const baseTheme = { colors: { primary: 'blue' } };
 * const additionalTheme = { colors: { secondary: 'green' } };
 * const mergedTheme = deepMerge(baseTheme, additionalTheme);
 * // Result: { colors: { primary: 'blue', secondary: 'green' } }
 */
function deepMerge(base: ThemeObject, ...objects: ThemeObject[]): ThemeObject {
  const result = { ...base };

  objects.forEach((current) => {
    if (!current) return;

    Object.keys(current).forEach((key) => {
      const currentValue = current[key];
      const baseValue = result[key];

      if (
        currentValue &&
        baseValue &&
        typeof currentValue === "object" &&
        typeof baseValue === "object" &&
        !Array.isArray(currentValue) &&
        !Array.isArray(baseValue)
      ) {
        result[key] = deepMerge(
          baseValue as ThemeObject,
          currentValue as ThemeObject,
        );
      } else if (currentValue !== undefined) {
        result[key] = currentValue;
      }
    });
  });

  return result;
}

const mergedTheme = deepMerge(
  themev2 as unknown as ThemeObject,
  themev3 as unknown as ThemeObject,
);

const config: Config = {
  content: [
    "./src/**/*.{js,ts,jsx,tsx,mdx}",
    "./storybook/**/*.{js,ts,jsx,tsx,mdx}",
  ],
  theme: {
    extend: mergedTheme,
  },
  darkMode: "class",
  plugins: [tailwindRAC, tailwindAnimate],
};

export default config;
