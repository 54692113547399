import { subWeeks } from "date-fns";

export interface DeviceData {
  id: string;
  createdAt: string;
  type: string;
}

export interface SiteData {
  id: string;
  createdAt: string;
  location: {
    streetOne: string;
    streetTwo: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    latitude: number;
    longitude: number;
  };
}

export interface HomePageResponse {
  devices: {
    data: DeviceData[];
    meta: { totalCount: number };
  };
  sites: {
    data: SiteData[];
  };
}

// Utility function to calculate counts
export const calculateCounts = (
  data: HomePageResponse,
  type: "devices" | "sites",
): { countThisWeek: number; totalCount: number } => {
  if (!data || !data[type] || !data[type].data)
    return { countThisWeek: 0, totalCount: 0 };

  const now = new Date();
  const oneWeekAgo = subWeeks(now, 1);

  let currentCount = 0;

  data[type].data.forEach((item) => {
    const createdAt = new Date(item.createdAt);
    if (createdAt >= oneWeekAgo && createdAt <= now) {
      currentCount += 1;
    }
  });

  return { countThisWeek: currentCount, totalCount: data[type].data.length };
};

// Utility function to format data for charts
export const formatDataForChart = (
  data: HomePageResponse,
  type: "devices" | "sites",
): { time: Date; value: number }[] => {
  if (!data || !data[type] || !data[type].data) return [];

  const itemsByDay = data[type].data.reduce<Record<string, number>>(
    (acc, item) => {
      const dateKey = new Date(item.createdAt).toISOString().split("T")[0];
      acc[dateKey] = (acc[dateKey] || 0) + 1;
      return acc;
    },
    {},
  );

  const sortedDates = Object.keys(itemsByDay).sort();
  let cumulativeCount = 0;

  const chartData = sortedDates.map((date) => {
    cumulativeCount += itemsByDay[date];
    return {
      time: new Date(date),
      value: cumulativeCount,
    };
  });

  // We want to show the current day's count, so we add it to the end of the array
  chartData.push({
    time: new Date(),
    value: cumulativeCount,
  });

  return chartData;
};

// Calculate growth percentages WoW, ensuring no division by zero
export const calculateGrowth = (
  countThisWeek: number,
  totalCount: number,
): number => {
  if (totalCount === 0) {
    return 0;
  }
  return 100 - ((totalCount - countThisWeek) / totalCount) * 100;
};
