// @ts-nocheck

"use client";

import { decodeJwt } from "jose";
import { useApolloClient } from "@apollo/client";
import { isNil } from "lodash";

import {
  SignUpUserInput,
  AuthenticateByEmailPasswordInput,
  LoggedUser,
} from "@/@codegen/supergraph";

import useAnalytics from "@/hooks/useAnalytics";

import { useLoggedUser } from "@/state/user/useLoggedUser";
import { useUserRepository } from "@/repositories/userRepository";
import { useUserService } from "@/services/userService";
import useLoggedUserFunctions from "@/hooks/useLoggedUserFunctions";
import useLocalStorage from "@/hooks/useLocalStorage";

import { toast } from "react-hot-toast";

export function useAuthenticationService() {
  const { identify, track } = useAnalytics();
  const userRepository = useUserRepository();
  const userService = useUserService();
  const { getTrackingUserData } = useLoggedUserFunctions();

  const localStorage = useLocalStorage();

  const apolloClient = useApolloClient();

  const { clearLoggedUser, loggedUser } = useLoggedUser();

  async function signIn({ rememberMe, ...input }: SignInInput) {
    try {
      const { bearerToken, refreshToken } = await userRepository.signIn(input);

      localStorage.setToken(bearerToken);

      if (rememberMe) {
        localStorage.setRefreshToken(refreshToken);
      }

      const loggedUser = await userService.fetchLoggedUser();

      const trackingUserData = getTrackingUserData();
      identify(trackingUserData);

      track("user-sign-in", { email: input.email, success: true });

      return loggedUser;
    } catch (error: any) {
      toast.error(error?.message ?? "Invalid email or password");
      track("user-sign-in", { email: input.email, success: false });
      throw error;
    }
  }

  async function signUp(input: SignUpUserInput) {
    try {
      const signUpResponse = (await userRepository.signUp(input)) as LoggedUser;
      if (isNil(signUpResponse?.id)) {
        throw new Error("Unexpected sign up payload");
      }

      track("user-sign-up", { email: input.email, success: true });

      return signUpResponse;
    } catch (error: any) {
      track("user-sign-up", { email: input.email, success: false });
      toast.error(error?.message ?? "Error signing up");
      throw error;
    }
  }

  async function signOut() {
    if (!loggedUser) {
      return;
    }
    try {
      localStorage.removeToken();
      localStorage.removeRefreshToken();
      apolloClient.clearStore();
      clearLoggedUser();
      track("user-sign-out", { success: true, email: loggedUser.email?.email });
    } catch (error) {
      console.error("Error signing out", error);
      track("user-sign-out", {
        success: false,
        email: loggedUser.email?.email,
      });
      throw error;
    }
  }

  async function refreshSession(organizationId?: string) {
    try {
      const refreshTokenOld = localStorage.getRefreshToken();

      if (!refreshTokenOld) {
        throw new Error("No refresh token found");
      }

      const { bearerToken, refreshToken } =
        await userRepository.refreshAuthenticationSession(
          refreshTokenOld,
          organizationId,
        );

      localStorage.setToken(bearerToken);
      localStorage.setRefreshToken(refreshToken);

      const loggedUser = await userService.fetchLoggedUser();

      track("user-refresh", {
        success: true,
        email: loggedUser.email?.email,
        organizationId,
      });

      const trackingUserData = getTrackingUserData();
      identify(trackingUserData);

      return loggedUser;
    } catch (error) {
      signOut();
      console.error("Error refreshing session", error);
      track("user-refresh", {
        success: false,
        email: loggedUser?.email?.email ?? "",
        organizationId,
      });
      throw error;
    }
  }

  function getIsAuthenticated() {
    const authToken = localStorage.getToken();

    if (authToken && authToken !== "undefined") {
      const decodedToken = decodeJwt(authToken);

      const dateNow = new Date().getTime() / 1000;

      return (decodedToken.exp ?? 0) > dateNow;
    }
    return false;
  }

  return { signIn, signUp, signOut, refreshSession, getIsAuthenticated };
}

interface SignInInput extends AuthenticateByEmailPasswordInput {
  rememberMe?: boolean;
  organizationId?: string;
}
