import { classNames } from "@/utils";
export * from "./Text";
export * from "./Heading";
interface HProps {
  iconLeft?: IconProp;
  iconRight?: IconProp;
  bold?: boolean;
}
export type IconProp = React.ReactNode | ((props: any) => React.ReactNode);
export function H4(props: React.PropsWithChildren<HProps>) {
  return <span className={classNames("row flex items-center text-base text-text-body", props.bold ? "font-medium" : "")} data-sentry-component="H4" data-sentry-source-file="index.tsx">
      {props.iconLeft && <span className="mr-2">{renderIcon(props.iconLeft)}</span>}
      <span>{props.children}</span>
      {props.iconRight && <span className="mr-2">{renderIcon(props.iconRight)}</span>}
    </span>;
}
function renderIcon(iconProp: IconProp) {
  if (typeof iconProp === "function") {
    return iconProp({});
  }
  return iconProp;
}