import React from "react";
import { Menu, MenuItem, MenuTrigger, Popover, Separator } from "react-aria-components";
import type { MenuItemProps } from "react-aria-components";
import { Avatar } from "@/edges/ui/Avatar";
import { Button } from "@/edges/ui/Button";
import { Icon } from "@/edges/ui/Icon";
import { Modal } from "@/edges/ui/Modal";
import { Dialog } from "@/edges/ui/Dialog";
import { OrganizationSwitcher } from "@/edges/ui/OrganizationSwitcher";
import { useTranslations } from "next-intl";
import { ROUTES } from "@/routes";
import { useAuthenticationService } from "@/services/authenticationService";
import { useNavigationService } from "@/services/navigationService";
import { useLoggedUser } from "@/state/user/useLoggedUser";
import OrganizationList from "../OrganizationSwitcher/components/OrganizationList";
interface UserMenuItem {
  id: string;
  name: string;
  iconName: string;
  route?: string;
}
enum UserMenuVariant {
  dashUser = "dash-user",
  externalUser = "external-user",
}
interface UserMenuProps {
  align?: "left" | "right";
  className?: string;
  variant?: UserMenuVariant | keyof typeof UserMenuVariant;
}
const userMenuItems: UserMenuItem[] = [{
  id: "profile",
  name: "Profile",
  iconName: "UserCircle",
  route: ROUTES.private.profile
}, {
  id: "documentation",
  name: "Documentation",
  iconName: "BookOpen",
  route: "https://docs.texturehq.com/docs/home/welcome"
}, {
  id: "apiReference",
  name: "API Reference",
  iconName: "Code",
  route: "https://docs.texturehq.com/api"
}, {
  id: "status",
  name: "Status",
  iconName: "CircleDashed",
  route: "https://status.texturehq.com/"
}, {
  id: "slack",
  name: "Join us on Slack",
  iconName: "SlackLogo",
  route: "https://join.slack.com/t/texture-community/shared_invite/zt-2ho02ds2o-pBNH1ZGcopxgIY3BgKcKzg"
}];
const signOutItem: UserMenuItem = {
  id: "logout",
  name: "Sign out",
  iconName: "SignOut"
};
function UserMenu({
  className,
  align = "right",
  variant = UserMenuVariant.dashUser
}: UserMenuProps) {
  const t = useTranslations();
  const {
    loggedUser
  } = useLoggedUser();
  const {
    signOut: signOutAuth
  } = useAuthenticationService();
  const navigationService = useNavigationService();
  function signOut() {
    signOutAuth();
    navigationService.navigateToSignIn(true);
  }
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const onAction = (item: UserMenuItem) => () => {
    if (!item.route) {
      return;
    }
    navigationService.navigateTo(item.route);
  };
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const handleSwitchOrganization = () => {
    openDialog();
    closeMenu();
  };
  if (!loggedUser) {
    return null;
  }
  return <div className={className} data-sentry-component="UserMenu" data-sentry-source-file="UserMenu.tsx">
      <Modal isDismissable isOpen={isDialogOpen} onOpenChange={closeDialog} data-sentry-element="Modal" data-sentry-source-file="UserMenu.tsx">
        <Dialog data-sentry-element="Dialog" data-sentry-source-file="UserMenu.tsx">
          <div className="p-4">
            <OrganizationList onSwitchOrganization={closeDialog} data-sentry-element="OrganizationList" data-sentry-source-file="UserMenu.tsx" />
          </div>
        </Dialog>
      </Modal>
      <MenuTrigger isOpen={isMenuOpen} onOpenChange={setIsMenuOpen} data-sentry-element="MenuTrigger" data-sentry-source-file="UserMenu.tsx">
        <Button variant="icon" data-sentry-element="Button" data-sentry-source-file="UserMenu.tsx">
          <Avatar size="sm" imageUrl={loggedUser?.profile?.profilePhoto || ""} firstName={loggedUser?.profile?.firstName || ""} lastName={loggedUser?.profile?.lastName || ""} data-sentry-element="Avatar" data-sentry-source-file="UserMenu.tsx" />
        </Button>
        <Popover className="w-[360px] overflow-auto rounded border bg-background-surface p-1 shadow-lg ring-borders ring-opacity-5 fill-mode-forwards entering:animate-in entering:fade-in entering:zoom-in-95 exiting:animate-out exiting:fade-out exiting:zoom-out-95 dark:ring-bordersDark" placement={align === "right" ? "bottom end" : "bottom start"} data-sentry-element="Popover" data-sentry-source-file="UserMenu.tsx">
          <Menu className="outline-none" data-sentry-element="Menu" data-sentry-source-file="UserMenu.tsx">
            {variant === UserMenuVariant.dashUser && <>
                <MenuItem>
                  <div className="py-3">
                    <OrganizationSwitcher onSwitchOrganization={handleSwitchOrganization} />
                  </div>
                </MenuItem>
                <Separator className="my-1 border-t border-borders dark:border-bordersDark" />
                {userMenuItems.map(item => <UserMenuItem key={item.id} id={item.id} onAction={onAction(item)} iconName={item.iconName}>
                    {item.name}
                  </UserMenuItem>)}
                <Separator className="my-1 border-t border-borders dark:border-bordersDark" />
              </>}
            <UserMenuItem key={signOutItem.id} id={signOutItem.id} onAction={signOut} iconName={signOutItem.iconName} data-sentry-element="UserMenuItem" data-sentry-source-file="UserMenu.tsx">
              {signOutItem.name}
            </UserMenuItem>
          </Menu>
        </Popover>
      </MenuTrigger>
    </div>;
}
interface UserMenuItemProps extends Omit<MenuItemProps, "onAction" | "children"> {
  onAction?: () => void;
  iconName: string;
  children: string;
}
export function UserMenuItem({
  iconName,
  children,
  ...props
}: UserMenuItemProps) {
  return <MenuItem {...props} aria-label={children} className="dark:focus:text-action-primaryDark group box-border flex w-full cursor-pointer items-center px-4 py-2 outline-none focus:bg-highlight dark:focus:bg-highlightDark" data-sentry-element="MenuItem" data-sentry-component="UserMenuItem" data-sentry-source-file="UserMenu.tsx">
      <Icon name={iconName} size={20} className="mr-2" data-sentry-element="Icon" data-sentry-source-file="UserMenu.tsx" />
      {children}
    </MenuItem>;
}
export { UserMenu };