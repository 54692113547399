import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/opt/render/project/src/apps/dash-next/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Epilogue\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"800\"],\"display\":\"swap\",\"variable\":\"--font-epilogue\"}],\"variableName\":\"epilogue\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/render/project/src/apps/dash-next/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/components/Error/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/ApolloClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AriaRouterProvider"] */ "/opt/render/project/src/apps/dash-next/src/providers/AriaRouterProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientProviders"] */ "/opt/render/project/src/apps/dash-next/src/providers/ClientProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/GrowthBookProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/NavigationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/render/project/src/apps/dash-next/src/providers/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/render/project/src/apps/dash-next/src/styles/legacy-scaffold.css");
