import React from "react";
import { Modal } from "@/edges/ui/Modal";
import { Dialog } from "@/edges/ui/Dialog";
import { Button } from "@/edges/ui/Button";
import { Separator } from "@/edges/ui/Separator";
import { Icon } from "@/edges/ui/Icon";
import { Menu, MenuItem } from "react-aria-components";
type Item = {
  id: string;
  name: string;
};
export interface ModalListWithAddButtonProps<T extends Item> {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  items: T[];
  selectedItemId: string;
  onSelectItem: (item: T) => void;
  addButtonText?: string;
  addButtonOnPress?: () => void;
}
const ModalListWithAddButton = <T extends Item,>({
  title,
  isOpen,
  onClose,
  items,
  selectedItemId,
  onSelectItem,
  addButtonText,
  addButtonOnPress
}: ModalListWithAddButtonProps<T>) => {
  const handleOnOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };
  return <Modal isDismissable isOpen={isOpen} onOpenChange={handleOnOpenChange} data-sentry-element="Modal" data-sentry-component="ModalListWithAddButton" data-sentry-source-file="index.tsx">
      <Dialog data-sentry-element="Dialog" data-sentry-source-file="index.tsx">
        <div className="p-4">
          <div className="text-foreground text-center text-base font-medium">
            {title}
          </div>
          <Separator className="my-1 border-t border-borders dark:border-bordersDark" data-sentry-element="Separator" data-sentry-source-file="index.tsx" />
          <Menu data-sentry-element="Menu" data-sentry-source-file="index.tsx">
            {items.map(item => <MenuItem key={item.id} onAction={() => onSelectItem(item)} className="dark:focus:text-action-primaryDark group box-border flex w-full cursor-pointer items-center px-4 py-2 outline-none focus:bg-highlight dark:focus:bg-highlightDark">
                <span className="flex-grow">{item.name}</span>
                {item.id === selectedItemId && <Icon name="Check" size={20} className="dark:text-action-primaryDark text-action-primary" />}
              </MenuItem>)}
            {addButtonOnPress && <>
                <Separator className="my-1 border-t border-borders dark:border-bordersDark" />
                <MenuItem onAction={addButtonOnPress}>
                  <Button fullWidth variant="secondary" onPress={addButtonOnPress} icon="Plus">
                    {addButtonText}
                  </Button>
                </MenuItem>
              </>}
          </Menu>
        </div>
      </Dialog>
    </Modal>;
};
export default ModalListWithAddButton;