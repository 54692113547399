import { useEffect, useRef, useMemo } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-github_dark";
export interface CodeEditorProps {
  /**
   * The code content to display
   */
  value: string;
  /**
   * Whether the editor is read-only
   */
  readOnly?: boolean;
  /**
   * Callback when the content changes
   */
  onChange?: (value: string) => void;
  /**
   * The height of the editor. If not provided, height will be calculated based on content.
   */
  height?: string;
  /**
   * The width of the editor
   * @default "100%"
   */
  width?: string;
  /**
   * Additional className to apply
   */
  className?: string;
  /**
   * Line height in pixels for auto-height calculation
   * @default 19
   */
  lineHeight?: number;
  /**
   * Minimum number of lines to show when auto-sizing
   * @default 3
   */
  minLines?: number;
  /**
   * Maximum number of lines to show when auto-sizing
   * @default 50
   */
  maxLines?: number;
}
export function CodeEditor({
  value,
  readOnly = false,
  onChange,
  height,
  width = "100%",
  className,
  lineHeight = 19,
  minLines = 3,
  maxLines = 50
}: CodeEditorProps) {
  const editorRef = useRef<AceEditor>(null);

  // Calculate height based on content if no height provided
  const calculatedHeight = useMemo(() => {
    if (height) return height;
    const lineCount = (value.match(/\n/g) || []).length + 1;
    const lines = Math.max(minLines, Math.min(lineCount, maxLines));
    return `${lines * lineHeight}px`;
  }, [value, height, lineHeight, minLines, maxLines]);
  useEffect(() => {
    // Ensure the editor is properly sized after mounting
    if (editorRef.current?.editor) {
      editorRef.current.editor.resize();
    }
  }, [calculatedHeight]);
  return <div style={{
    width,
    height: calculatedHeight
  }} data-sentry-component="CodeEditor" data-sentry-source-file="CodeEditor.tsx">
      <AceEditor ref={editorRef} mode="json" theme="github_dark" value={value} onChange={onChange} readOnly={readOnly} width="100%" height="100%" className={className} maxLines={maxLines} minLines={minLines} setOptions={{
      useWorker: true,
      showLineNumbers: true,
      tabSize: 2,
      showPrintMargin: false,
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true
    }} data-sentry-element="AceEditor" data-sentry-source-file="CodeEditor.tsx" />
    </div>;
}