// @ts-nocheck

import React from "react";
import { TextField as AriaTextField, TextFieldProps as AriaTextFieldProps, ValidationResult } from "react-aria-components";
import { Description, FieldError, Input, Label } from "../Field";
import { composeTailwindRenderProps, focusRing } from "@/edges/utils";
import { getFieldBorderStyles } from "../Field";
const sizeStylesObject = {
  sm: "min-h-[theme(controlElementSize.sm)] max-h-[theme(controlElementSize.sm)] text-controlElementSm",
  md: "min-h-[theme(controlElementSize.md)] max-h-[theme(controlElementSize.md)] text-controlElementMd",
  lg: "min-h-[theme(controlElementSize.lg)] max-h-[theme(controlElementSize.lg)] text-controlElementLg",
  xl: "min-h-[theme(controlElementSize.xl)] max-h-[theme(controlElementSize.xl)] text-controlElementXl"
};
const getInputStyles = (props: {
  isFocused?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
}) => {
  const baseStyles = `${focusRing()} rounded-sm`;
  const borderStyles = getFieldBorderStyles({
    isFocusWithin: props.isFocused,
    isInvalid: props.isInvalid,
    isDisabled: props.isDisabled
  });
  const sizeStyles = props.size ? sizeStylesObject[props.size] : sizeStylesObject["md"];
  const styles = `${baseStyles} ${borderStyles} ${sizeStyles}`.trim();
  return styles;
};
export interface TextFieldProps extends AriaTextFieldProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: ValidationResult) => string);
  placeholder?: string;
  size?: "sm" | "md" | "lg" | "xl";
  tooltip?: string;
  isRequired?: boolean;
}
export function TextField({
  label,
  description,
  errorMessage,
  size = "md",
  tooltip,
  isRequired,
  ...props
}: TextFieldProps) {
  return <AriaTextField
  // @ts-expect-error To be handled in another PR. We have an issue with the required prop from FormDialog
  isRequired={props.required ? undefined : isRequired} {...props} className={composeTailwindRenderProps(props.className, `flex flex-col gap-1`)} data-sentry-element="AriaTextField" data-sentry-component="TextField" data-sentry-source-file="TextField.tsx">
      {({
      isFocused,
      isInvalid,
      isDisabled
    }) => <div className="flex flex-col gap-1">
          {label && <Label className="pb-1" tooltip={tooltip} isRequired={isRequired}>
              {label}
            </Label>}
          <Input className={getInputStyles({
        isFocused,
        isInvalid,
        isDisabled,
        size
      })} />
          {description && <Description>{description}</Description>}
          <FieldError>{errorMessage}</FieldError>
        </div>}
    </AriaTextField>;
}